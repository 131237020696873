import React, { useState } from "react";
import { Box, Button, Paper, Stack } from "@mui/material";
import ICardGroup from "../../../types/ICardGroup";
import CardGroupItem from "./CardGroupItem";
import { createCardGroup, deleteCardGroup } from "../../../services/card.service";
import useAlerts from "../../../hooks/useAlerts";

export default function CartGroupList({
    data,
    cardId, blocked
}: {
    cardId: number,
    data: ICardGroup[],
    blocked: boolean
}) {
    const [groups, setGroups] = useState<ICardGroup[]>(data || [])
    const { error, success } = useAlerts()

    function handleCreateGroup() {
        createCardGroup(cardId)
            .then(({ data }) => setGroups([...groups, data]))
            .catch(err => error('ups, coś poszło nie tak'))
    }

    function handleDelete(groupId: string | number) {
        deleteCardGroup(groupId)
            .then(() => {
                setGroups(groups.filter(g => g.id !== groupId))
                success("Pomyślnie usunięto grupę")
            })
            .catch(() => {
                error("Ups, coś poszło nie tak podczas usuwania grupy")
            })
    }

    // useEffect(() => {
    //     setGroups(data)
    // }, [data]) //to jest nie potrzebne bo nie powoduje tutaj bugu

    return <Box>
        {groups.sort((b, a) => a.index - b.index).map((group, ix) => <CardGroupItem
            key={ix}
            data={group}
            cardId={cardId}
            onDelete={() => handleDelete(group.id)}
            blocked={blocked}
        />)}
        {!blocked && (
            <Paper>
                <Box p={0.5}>
                    <Stack width={'100%'} direction={'row-reverse'} px={1}>
                        <Button onClick={handleCreateGroup}>Dodaj grupę</Button>
                    </Stack>
                </Box>
            </Paper>
        )}
    </Box>
}