import { useSnackbar } from "notistack";

export default function useAlerts() {
    const { enqueueSnackbar } = useSnackbar();

    return {
        success: (msg: string = "Wykonano") => enqueueSnackbar(msg, { variant: "success" }),
        error: (msg: string = "Ups, coś poszło nie tak") => enqueueSnackbar(msg, { variant: "error" }),
        info: (msg: string) => enqueueSnackbar(msg, { variant: "info" }),
        warning: (msg: string = "Uwaga") => enqueueSnackbar(msg, { variant: "warning" })
    }
}