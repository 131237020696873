import BreadcrumbsContainer from '@mui/material/Breadcrumbs';
import React from "react";
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import routes from "../../config/routes";
import { Link } from "react-router-dom";


export default function Breadcrumbs(): JSX.Element {

    const breadcrumbs = useReactRouterBreadcrumbs(routes);

    return <>
        <BreadcrumbsContainer separator="›" aria-label="breadcrumb">
            {
                breadcrumbs.map(({
                    match,
                    breadcrumb
                }, index) => {
                    return <Link style={{ textDecoration: 'none', color: '#000' }} to={match.pathname} key={match.pathname}>
                        {breadcrumb}
                    </Link>
                })
            }
        </BreadcrumbsContainer>
    </>
}