import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Autocomplete, Box, Grid, Stack, TextField, Typography} from "@mui/material";
import IGroup from "../../types/IGroup";
import IUser from "../../types/IUser";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator"
import {getAllCompanies} from "../../services/company.service";
import useAlerts from "../../hooks/useAlerts";
import {getUsersParams} from "../../services/user.service";
import {paramFilter} from "../../utils/filterUtils";
import ICompany from "../../types/ICompany";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

export default function GroupForm({
                                      onSubmit,
                                      data,
                                      setData,
                                      loadingBtn
                                  }: { loadingBtn: boolean, onSubmit: (data: IGroup) => void, data: IGroup, setData: Dispatch<SetStateAction<IGroup>> | Dispatch<SetStateAction<IGroup | undefined>> }) {

    const [companies, setCompanies] = useState<ICompany[]>([]);
    const [teachers, setTeachers] = useState<IUser[]>([]);
    const {error} = useAlerts()

    function getCompanyFromId(sss: Number): ICompany | undefined {
        if (typeof sss === 'object' && sss !== null) {
            return sss as unknown as ICompany;
        } else {
            return companies?.find(x => x.id === sss);
        }
    }

    function getTeachersFromData(dataUsers: IUser[]): IUser[] {
        if (dataUsers !== undefined) {
            var filteredTrainers = dataUsers.filter(function (user) {
                return user.role === "Trainer";
            });
            return filteredTrainers;
        } else {
            return [];
        }
    }

    function handleChange(event: React.FormEvent<any>) {
        setData({...data, [event.currentTarget.name]: event.currentTarget.value})
    }

    function handleChangeAutocomplete(company: any) {
        setData({...data, 'company': company})
    }

    function handleChangeAutocompleteTeachers(teachers: IUser[]) {

        let current;

        if (data.users) {
            const users = data.users.filter(function (user) {
                return user.role !== "Trainer";
            });

            if (Array.isArray(teachers) && teachers.length) {
                current = [...users, ...teachers]
            } else {
                current = [...users]
            }
        } else {
            current = [...teachers]
        }

        setData({...data, 'users': current})

    }

    function handleSubmit(a: React.FormEvent) {
        onSubmit({...data})
    }

    useEffect(() => {
        getAllCompanies()
            .then(({data}) => {
                setCompanies(data[0])
            })
            .catch(err => {
                setCompanies([])
                console.error(err);
                error('Nie można pobrać danych');
            })

        getUsersParams(paramFilter('role', 'Trainer'))
            .then(({data}) => {
                setTeachers(data[0]);
            })
            .catch(err => {
                setTeachers([])
                console.error(err);
                error();
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Box p={4}>
        <Typography variant="h6" pb={4}>Dane</Typography>
        <ValidatorForm
            onSubmit={handleSubmit}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 5,
                }}
                mb={4}
            >
                <TextValidator
                    label="Nazwa grupy*"
                    onChange={handleChange}
                    name="name"
                    value={data.name || ""}
                    variant={'standard'}
                    validators={['required']}
                    errorMessages={['Pole jest wymagane']}
                    fullWidth
                />
                <Autocomplete
                    freeSolo
                    disabled
                    id="company"
                    disableClearable
                    onChange={(event, newValue) => {
                        if (typeof newValue === "object") {
                            handleChangeAutocomplete(newValue);
                        }
                    }}
                    options={companies ? companies : []}
                    getOptionLabel={(option: ICompany): string => {
                        return option.name ? option.name : '';
                    }}
                    value={getCompanyFromId(data.company as Number) || ''}
                    renderOption={(props, companies) => {
                        return (
                            <li {...props} key={companies.id}>
                                {companies.name}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Wyszukaj firmę"
                            required={true}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                        />
                    )}
                />
                <Autocomplete
                    multiple
                    id="teachers"
                    onChange={(event, newValue) => {
                        if (typeof newValue === "object") {
                            handleChangeAutocompleteTeachers(newValue);
                        }
                    }}
                    options={teachers ? teachers : []}
                    getOptionLabel={(option: IUser): string => {
                        return option.name ? option.name : '';
                    }}
                    value={getTeachersFromData(data.users as IUser[]) || ''}
                    renderOption={(props, teachers) => {
                        return (
                            <li {...props} key={teachers.id}>
                                {teachers.name}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Dodaj nauczyciela"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                        />
                    )}/>
            </Box>
            <Grid>
                <Stack direction="row" mt={4} justifyContent="end">
                    <LoadingButton
                        loading={loadingBtn}
                        loadingPosition="start"
                        startIcon={<SaveIcon/>}
                        variant="outlined"
                        type={'submit'}
                    >
                        Zapisz
                    </LoadingButton> </Stack>
            </Grid>
        </ValidatorForm>
    </Box>
}