import { Box, SpeedDial, SpeedDialIcon } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { R_ELEARNING_CREATE } from "../../config/routes";
// import QuizIcon from '@mui/icons-material/Quiz';
// import CoPresentIcon from '@mui/icons-material/CoPresent';
import TableElearning from "./tables/TableElearning";

export default function Elearning(): JSX.Element {

    // const [value, setValue] = React.useState(0);
    const navigate = useNavigate();

    // const actions = [
    //     { icon: <QuizIcon />, name: 'Quiz/Test', target: R_ELEARNING_CREATE },
    //     { icon: <CoPresentIcon />, name: 'Prezentacja', target: '' },
    // ];

    // const handleChange = (event: any, newValue: any) => {
    //     setValue(newValue);
    // };

    function TabPanel(props: any) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <>{children}</>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    return <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={4}>
            <TableElearning />
        </Box>
        <SpeedDial
            ariaLabel="Dodaj Szkolenie"
            sx={{ position: 'fixed', bottom: 16, right: 16, cursor: "pointer" }}
            icon={<SpeedDialIcon />}
            onClick={() => navigate(R_ELEARNING_CREATE)}
            open={false}
        >
        </SpeedDial>
    </Box >
}