import React, {Dispatch, SetStateAction} from "react";
import {Typography, Box} from "@mui/material";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator"
import IAuth from "../../types/IAuth";
import LoadingButton from "@mui/lab/LoadingButton";

export default function LoginForm({
                                      onSubmit,
                                      data,
                                      setData,
                                      loadingBtn
                                  }: {
    loadingBtn: boolean,
    onSubmit: (data: IAuth) => void,
    data: IAuth,
    setData: Dispatch<SetStateAction<IAuth>> | Dispatch<SetStateAction<IAuth | undefined>>
}) {

    function handleChange(event: React.FormEvent<any>) {
        setData({
            ...data, [event.currentTarget.name]: event.currentTarget.value
        })
    }

    function handleSubmit(a: React.FormEvent) {
        onSubmit({...data})
    }

    return <ValidatorForm
        onSubmit={handleSubmit}
    >
        <Box sx={{mb: 4}}>
            <Typography
                color="textPrimary"
                variant="h4"
            >
                Logowanie
            </Typography>
        </Box>
        <TextValidator
            label="Adres e-mail"
            onChange={handleChange}
            name="email"
            placeholder="Wpisz adres e-mail"
            variant='outlined'
            margin="normal"
            value={data.email || ""}
            validators={['required', 'isEmail']}
            errorMessages={['Pole jest wymaganie', 'Adres e-mail jest nie poprawny']}
            fullWidth
        />
        <TextValidator
            label="Hasło"
            onChange={handleChange}
            name="password"
            type="password"
            placeholder="Wpisz hasło"
            margin="normal"
            variant='outlined'
            value={data.password || ""}
            validators={['required']}
            errorMessages={['Pole jest wymaganie']}
            fullWidth
        />
        <Box sx={{py: 2}}>
            <LoadingButton
                color="primary"
                fullWidth
                size="large"
                loading={loadingBtn}
                loadingPosition="start"
                variant="outlined"
                type={'submit'}
            >
                Zaloguj
            </LoadingButton>
        </Box>
    </ValidatorForm>

}