import { Container } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAlerts from "../../hooks/useAlerts";
import { authLogin } from "../../services/auth.service";
import IAuth from "../../types/IAuth";
import {
  setCompany,
  setIsAuthenticated,
  setRefreshTokenExpirationTimestamp,
  setTokenUser,
} from "../../utils/tokenUtils";
import LoginForm from "./LoginForm";
import { R_INDEX } from "../../config/routes";

export default function Login(): JSX.Element {
  const [data, setData] = useState<IAuth>({
    email: "",
    password: "",
  });
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  const navigate = useNavigate();
  const { error } = useAlerts();

  function handleSubmit(data: IAuth) {
    setLoadingBtn(true);

    authLogin(data)
      .then(({ data }) => {
        if (data.user) {
          setTokenUser(data.user);
          setIsAuthenticated(true);
        }

        if (data.company?.id) {
          setCompany(data.company);
        }

        if (data.refreshTokenExpirationTimestamp) {
          setRefreshTokenExpirationTimestamp(
            data.refreshTokenExpirationTimestamp
          );
        }

        navigate(R_INDEX);
      })
      .catch((err) => {
        console.error(err);
        error("Błędne dane logowania");
      })
      .finally(() => {
        setLoadingBtn(false);
      });
  }

  // if (location.pathname === R_REMIND) {
  //     return (
  //         <Container maxWidth="sm">
  //             <RemindForm data={data} setData={setData} onSubmit={handleSubmitRemind} />
  //         </Container>
  //     )
  // }

  // if (location.pathname === R_NEW_PASSWORD) {
  //     return (
  //         <Container maxWidth="sm">
  //             <PasswordForm data={data} setData={setData} onSubmit={handleSubmitRemind} />
  //         </Container>
  //     )
  // }

  return (
    <Container maxWidth="sm">
      <Box>
        <LoginForm
          data={data}
          setData={setData}
          onSubmit={handleSubmit}
          loadingBtn={loadingBtn}
        />
      </Box>
    </Container>
  );
}
