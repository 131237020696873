import { Avatar, Box, Button, Card, CardActions, CardContent, Divider } from "@mui/material";
import IImage from "../../../types/IImage";
import { BACKEND_URL } from "../../../config/config";
import Compress from "react-image-file-resizer";

export default function CompanyProfileImage({
    image,
    onFile
}: {
    onFile: (file: File) => void,
    image: IImage
}) {

    function handleChangeImage(e: any) {

        if (e.target.files && e.target.files[0]) {
            const image = e.target.files[0];

            Compress.imageFileResizer(
                image, // the file from input
                300, // width
                300, // height
                "JPEG", // compress format WEBP, JPEG, PNG
                85, // quality
                0, // rotation
                (uri) => {
                    onFile(uri as unknown as File);
                },
                "file" // default base64
            );

        }
    }

    return <Card>
        <CardContent>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Avatar
                    src={image.url ? `${BACKEND_URL}files/open/${image.key}` : ''}
                    sx={{
                        height: 120,
                        mb: 1,
                        width: 120
                    }}
                />
            </Box>
        </CardContent>
        <Divider />
        <CardActions>
            <label htmlFor="contained-button-file" className="inputImage">
                <input accept="image/*" id="contained-button-file" type="file" onChange={handleChangeImage} />
                <Button
                    color="primary"
                    fullWidth
                    variant="text"
                    component="span"
                >
                    Dodaj logo
                </Button>
            </label>
        </CardActions>
    </Card>
}
