import IRouteConfig from "../types/IRouteConfig";
import User from "../containers/user";
import Index from "../containers/index";
import Group from "../containers/group";
import Company from "../containers/company";
import Practic from "../containers/practic";
import Elearning from "../containers/elearning";
import CreateElearning from "../containers/elearning/CreateElearning";
import EditCompany from "../containers/company/EditCompany";
import CreateCompany from "../containers/company/CreateCompany";
import EditGroup from "../containers/group/EditGroup";
import CreateGroup from "../containers/group/CreateGroup";
import EditUser from "../containers/user/EditUser";
import CreateUser from "../containers/user/CreateUser";
import Logout from "../containers/auth/Logout";
import EditElearning from "../containers/elearning/EditElearning";
import CardList from "../containers/card/CardList";
import CreateCard from "../containers/card/CreateCard";
import EditCard from "../containers/card/EditCard";
import Settings from "../containers/settings";
import CreateTest from "../containers/test/CreateTest";
import EditTest from "../containers/test/EditTest";
import Test from "../containers/test";
import Login from "../containers/auth/Login";

export const R_INDEX = "/";
export const R_USER = "/user";
export const R_USER_EDIT = (id: string | number = ":id") => `/user/${id}`
export const R_USER_CREATE = '/user/create'
export const R_USER_FROM_COMPANY_CREATE = (id: string | number = ":id") => `/user/create/${id}`
export const R_TEST = '/test'
export const R_TEST_CREATE = `/test/create`
export const R_TEST_EDIT = (id: string | number = ":id") => `/test/${id}`
export const R_ELEARNING = '/e-learning'
export const R_ELEARNING_CREATE = `/e-learning/create`
export const R_ELEARNING_EDIT = (id: string | number = ":id") => `/e-learning/edit/${id}`
export const R_PRACTIC = '/practic'
export const R_GROUP = '/group'
export const R_GROUP_EDIT = (id: string | number = ":id") => `/group/${id}`
export const R_GROUP_CREATE = `/group/create`
export const R_GROUP_FROM_COMPANY_CREATE = (id: string | number = ":id") => `/group/create/${id}`
export const R_COMPANY = '/company'
export const R_COMPANY_EDIT = (id: string | number = ":id") => `/company/${id}`
export const R_COMPANY_CREATE = `/company/create`
export const R_LOGOUT = `/logout`
export const R_LOGIN = `/login`
export const R_SETT = '/settings'
export const R_REMIND = `/remind`
export const R_NEW_PASSWORD = `/new-password`
export const R_CARD = '/card'
export const R_CARD_CREATE = `/card/create`
export const R_CARD_EDIT = (id: string | number = ":id") => `/card/${id}`

const routes: IRouteConfig[] = [
    {
        path: R_INDEX,
        exact: true,
        breadcrumb: "Home",
        container: Index
    },
    {
        path: R_ELEARNING,
        exact: true,
        breadcrumb: "E-learning",
        container: Elearning
    },
    {
        path: R_PRACTIC,
        exact: true,
        breadcrumb: "Praktyka",
        container: Practic
    },
    {
        path: R_USER,
        exact: true,
        breadcrumb: "Użytkownicy",
        container: User
    },
    {
        path: R_GROUP,
        exact: true,
        breadcrumb: "Grupy",
        container: Group
    },
    {
        path: R_COMPANY,
        exact: true,
        breadcrumb: "Firmy",
        container: Company
    },
    {
        path: R_TEST,
        exact: true,
        breadcrumb: "Testy",
        container: Test
    },
    {
        path: R_COMPANY_CREATE,
        exact: true,
        breadcrumb: "Utwórz Firmę",
        container: CreateCompany
    },
    {
        path: R_COMPANY_EDIT(),
        exact: true,
        breadcrumb: "Edycja Firmy",
        container: EditCompany
    },
    {
        path: R_GROUP_CREATE,
        exact: true,
        breadcrumb: "Utwórz Grupę",
        container: CreateGroup
    },
    {
        path: R_TEST_CREATE,
        exact: true,
        breadcrumb: "Utwórz Test",
        container: CreateTest
    },
    {
        path: R_TEST_EDIT(),
        exact: true,
        breadcrumb: "Edycja Testu",
        container: EditTest
    },
    {
        path: R_GROUP_FROM_COMPANY_CREATE(),
        exact: true,
        breadcrumb: "Do Firmy",
        container: CreateGroup
    },
    {
        path: R_USER_FROM_COMPANY_CREATE(),
        exact: true,
        breadcrumb: "Do Firmy",
        container: CreateUser
    },
    {
        path: R_ELEARNING_CREATE,
        exact: true,
        breadcrumb: "Utwórz",
        container: CreateElearning
    },
    {
        path: R_ELEARNING_EDIT(),
        exact: true,
        breadcrumb: "Edytuj",
        container: EditElearning
    },
    {
        path: R_GROUP_EDIT(),
        exact: true,
        breadcrumb: "Edycja Grupy",
        container: EditGroup
    },
    {
        path: R_USER_CREATE,
        exact: true,
        breadcrumb: "Utwórz użytkownika",
        container: CreateUser
    },
    {
        path: R_CARD,
        exact: true,
        breadcrumb: "Karty jazdy",
        container: CardList
    },
    {
        path: R_CARD_CREATE,
        exact: true,
        breadcrumb: "Utwórz kartę jazdy",
        container: CreateCard
    },
    {
        path: R_CARD_EDIT(),
        exact: true,
        breadcrumb: "Edycja karty jazdy",
        container: EditCard
    },
    {
        path: R_USER_EDIT(),
        exact: true,
        breadcrumb: "Edycja użytkownika",
        container: EditUser
    },
    {
        path: R_LOGIN,
        exact: true,
        breadcrumb: "Logowanie",
        container: Login
    },
    {
        path: R_LOGOUT,
        exact: true,
        breadcrumb: "Wylogowanie",
        container: Logout
    },
    {
        path: R_REMIND,
        exact: true,
        breadcrumb: "Przypomnienie hasła",
        container: Login
    },
    {
        path: R_SETT,
        exact: true,
        breadcrumb: "Ustawienia",
        container: Settings
    },
];

export default routes;