import React from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Box,  FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import ICard from "../../../types/ICard";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

export default function CardForm({
    data,
    onSubmit,
    onChange,
    handleChangeSwitch,
    changeSwitch,
    change,
    loadingBtn
}: {
    data?: ICard,
    onSubmit(e: React.FormEvent): void,
    onChange(e: React.FormEvent<any>): void,
    handleChangeSwitch(e: React.ChangeEvent<HTMLInputElement>): void,
    changeSwitch: boolean
    change: boolean
    loadingBtn: boolean
}) {

    return <Box p={4}>
        <Typography variant="h6" pb={4}>Dane</Typography>
        <ValidatorForm
            onSubmit={onSubmit}
        >
            <TextValidator
                label="Nazwa"
                onChange={onChange}
                name="name"
                value={data?.name || ""}
                variant={'standard'}
                validators={['required']}
                errorMessages={['Pole jest wymagane']}
                fullWidth
            />
            {data?.blockedAt === null && (
                <Box>
                    <Stack direction="column" mt={4} justifyContent="start">
                        <FormControlLabel control={<Switch checked={changeSwitch} onChange={handleChangeSwitch} />} label="Zablokuj edycję" />
                        <br />
                        <Typography mt={0} mb={0} variant={'caption'}>Karta zostanie udostępniona egzaminatorom.<br /><strong>Tego działania nie można cofnąć </strong></Typography>
                    </Stack>
                </Box>
            )}
            <Box>
                <Stack direction="row" mt={4} justifyContent="end">
                    <LoadingButton
                        disabled={!change}
                        loading={loadingBtn}
                        loadingPosition="start"
                        startIcon={<SaveIcon/>}
                        variant="outlined"
                        type={'submit'}
                    >
                        {change ? 'Zapisz' : 'Brak zmian'}
                    </LoadingButton>
                </Stack>
            </Box>
        </ValidatorForm>
    </Box>
}