import {BACKEND_URL} from "../config/config";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField
} from "@mui/material";
import React, {useState} from "react";
import Compress from "react-image-file-resizer";
import IImage from "../types/IImage";

function MediaUploader({alt, image, setImage, onRemove, onApply, onYoutube}: {
    alt: string
    image: IImage, setImage: React.Dispatch<React.SetStateAction<IImage>>
    onRemove(): void
    onApply(file: File): void
    onYoutube?: (url: string) => void
}) {
    const [open, setOpen] = useState<boolean>(false);
    const [url, setUrl] = useState<string>("");

    function handleFileChange(file: File) {
        Compress.imageFileResizer(
            file, // the file from input
            1080, // width
            720, // height
            "JPEG", // compress format WEBP, JPEG, PNG
            90, // quality
            0, // rotation
            (uri) => {
                onApply(uri as unknown as File)
            },
            "file" // default base64
        );
    }

    function handleChangeImage(e: any) {
        if (e.target.files && e.target.files[0]) {
            const img = e.target.files[0];
            handleFileChange(img);
        }
    }

    function handleRemoveImg() {
        onRemove()
    }

    return <Stack>
        {onYoutube && typeof onYoutube !== 'undefined' && <Dialog open={open} onClose={() => {
            setOpen(false)
        }}>
            <DialogTitle>Subscribe</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Wklej tutaj link z Youtube, powinien wyglądać
                    tak <strong>https://www.youtube.com/watch?v=ewRjZoRtu0Y</strong>
                </DialogContentText>
                <TextField
                    onChange={(e) => setUrl(e.target.value)}
                    value={url}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Youtube link"
                    type="url"
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpen(false)
                }}>Anuluj</Button>
                <Button onClick={() => {
                    onYoutube(url)
                    setOpen(false)
                }}>Dodaj Film</Button>
            </DialogActions>
        </Dialog>}
        <div style={{border: "1px solid black", display: "flex"}}>
            {image.type === 'youtube' ?
                <iframe width={"100%"} height={"auto"} src={`https://www.youtube.com/embed/${image.url}`}
                        title={"YouTube video player"} frameBorder={"0"}
                        allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
                        allowFullScreen/>
                :
                <img
                    src={image.url ? `${BACKEND_URL}files/open/${image.key}` : 'https://via.placeholder.com/240x240?text=Brak+zdjecia'}
                    alt={alt}
                    width={"100%"}
                    height={'auto'}/>
            }
        </div>
        {/*<video width="320" height="240" controls>*/}
        {/*    <source src={`${BACKEND_URL}/files/open/${image.key}`} type="video/*" />*/}
        {/*</video>*/}
        <input
            accept="image/*"
            style={{display: 'none'}}
            id="raised-button-file"
            type="file"
            onChange={handleChangeImage}
        />
        {onYoutube && typeof onYoutube !== 'undefined' &&
        <Button component="span" fullWidth onClick={() => setOpen(true)}>
            Link do Youtube
        </Button>}
        <label htmlFor="raised-button-file">
            <Button component="span" fullWidth>
                Wgraj obrazek
            </Button>
        </label>
        <Button component="span" color={'error'} disabled={image.id <= 0} onClick={handleRemoveImg}>
            Usuń obrazek
        </Button>
    </Stack>
}

export default MediaUploader