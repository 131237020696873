import React, { useEffect, useState } from "react"
import { Button, Card, CardContent, CardHeader, CircularProgress, Grid, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getUsersParams } from "../../services/user.service";
import { getAllCompanies } from "../../services/company.service";
import { paramFilter, paramLimit, paramSort } from "../../utils/filterUtils";
import { getTestsParams } from "../../services/test.service";
import { R_USER } from "../../config/routes";
import { Link } from "react-router-dom";
import IUser from "../../types/IUser";

const Index = (): JSX.Element => {

    const [companies, setCompanies] = useState<number>(-1);
    const [users, setUsers] = useState<IUser[]>();
    const [usersCount, setUsersCount] = useState<number>(-1);
    const [tests, setTests] = useState<number>(-1);
    const [quiz, setQuiz] = useState<number>(-1);

    useEffect(() => {
        getUsersParams(
            paramFilter('role', 'User')
        )
            .then(({ data }) => {
                setUsersCount(data[1]);
            })
            .catch(err => {
                console.error(err);
            })

        getUsersParams(
            paramFilter('role', 'User') +
            paramSort('id', 'DESC') +
            paramLimit(10)
        )
            .then(({ data }) => {
                setUsers(data[0]);
            })
            .catch(err => {
                console.error(err);
            })

        getAllCompanies()
            .then(({ data }) => {
                setCompanies(data[1]);
            })
            .catch(err => {
                console.error(err);
            })

        getTestsParams(
            paramFilter('timeOut', '0', '>')
        )
            .then(({ data }) => {
                setTests(data[1])
            })
            .catch(err => {
                console.error(err);
            })

        getTestsParams(
            paramFilter('timeOut', '0')
        )
            .then(({ data }) => {
                setQuiz(data[1])
            })
            .catch(err => {
                console.error(err);
            })


    }, [])

    return <Box>
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
            >
                <Card
                    sx={{ height: '100%' }}
                >
                    <CardContent>
                        <Grid
                            container
                            spacing={3}
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <Grid item>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="overline"
                                >
                                    FIRMY
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="h4"
                                >
                                    {companies === -1 && (
                                        <CircularProgress />
                                    )}
                                    {companies >= 0 && (
                                        companies
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
            >
                <Card
                    sx={{ height: '100%' }}
                >
                    <CardContent>
                        <Grid
                            container
                            spacing={3}
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <Grid item>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="overline"
                                >
                                    Ilość uczestników
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="h4"
                                >
                                    {usersCount === -1 && (
                                        <CircularProgress />
                                    )}
                                    {usersCount >= 0 && (
                                        usersCount
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
            >
                <Card
                    sx={{ height: '100%' }}
                >
                    <CardContent>
                        <Grid
                            container
                            spacing={3}
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <Grid item>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="overline"
                                >
                                    Ilość quizów
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="h4"
                                >
                                    {quiz === -1 && (
                                        <CircularProgress />
                                    )}
                                    {quiz >= 0 && (
                                        quiz
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
            >
                <Card
                    sx={{ height: '100%' }}
                >
                    <CardContent>
                        <Grid
                            container
                            spacing={3}
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <Grid item>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="overline"
                                >
                                    Ilość testów
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="h4"
                                >
                                    {tests === -1 && (
                                        <CircularProgress />
                                    )}
                                    {tests >= 0 && (
                                        tests
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
            >
                <Card>
                    <CardHeader title="Uczestnicy" subheader="Ostatnio dodani do platformy" />
                    {users && users.length !== 0 && (
                        <>
                            <Box sx={{ minWidth: 800 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Imię i nazwisko
                                            </TableCell>
                                            <TableCell>
                                                E-mail
                                            </TableCell>
                                            <TableCell>
                                                Telefon
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map((user) => (
                                            <TableRow
                                                hover
                                                key={user.id}
                                            >
                                                <TableCell>
                                                    {user.name}
                                                </TableCell>
                                                <TableCell>
                                                    {user.email}
                                                </TableCell>
                                                <TableCell>
                                                    {user.phone}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >
                                <Button
                                    color="primary"
                                    size="small"
                                    variant="text"
                                    component={Link}
                                    to={R_USER}
                                >
                                    Zobacz wszystkich
                                </Button>
                            </Box>
                        </>
                    )}
                    {!users && (
                        <Box sx={{ p: 2 }}>
                            <LinearProgress />
                        </Box>
                    )}
                </Card>
            </Grid>
        </Grid>
    </Box>
}

export default Index