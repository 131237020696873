import React, {useEffect, useState} from "react";
import {
    Alert,
    Box, Button, Container, LinearProgress, Paper
} from "@mui/material";
import ITest from "../../types/ITest";
import TestForm from "./components/TestForm";
import ITestQuestion from "../../types/ITestQuestion";
import {
    createTestQuestion,
    deleteTestQuestion,
    getTest,
    updateTest,
    updateTestQuestion
} from "../../services/test.service";
import useAlerts from "../../hooks/useAlerts";
import {useParams} from "react-router-dom";
import TestQuestionForm from "./components/TestQuestionForm";

export default function EditTest() {

    const q: ITestQuestion = {
        id: -1,
        test: 0,
        answer: [],
        answers: {
            "1": {en: "", pl: "", de: ""},
            "2": {en: "", pl: "", de: ""},
            "3": {en: "", pl: "", de: ""},
            "4": {en: "", pl: "", de: ""}
        },
        question: {en: "", pl: "", de: ""}
    }

    const {error, success} = useAlerts()
    const {id} = useParams();

    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)
    const [loadingBtn2, setLoadingBtn2] = useState<boolean>(false)

    const [data, setData] = useState<ITest>({
        id: -1, name: "", blockedAt: false, timeOut: 0, questions: []
    })

    const [blocked, setBlocked] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            getTest(id)
                .then(({data}) => {
                    setData({...data, questions: data.questions?.sort((a, b) => a.id - b.id)})
                    setBlocked(data.blockedAt !== null ? true : false)
                })
                .catch((err) => {
                    console.error(err);
                    error();
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    function handleSubmit(data: ITest) {
        setLoadingBtn(true)
        updateTest(data.id, {
            ...data,
            timeOut: data.timeOut && !isNaN(+data.timeOut) ? data.timeOut : undefined
        })
            .then(() => {
                success("Edytowano poprawnie")
            })
            .catch((err) => {
                error("Ups, coś poszło nie tak")
                console.error(err);
            })
            .finally(() => {
                setLoadingBtn(false)
            })
    }

    function handleDeleteQuestion(question: ITestQuestion) {
        deleteTestQuestion(data.id, question.id)
            .then(() => {
                success("Usunięto pytanie");
                setData({...data, questions: (data.questions as ITestQuestion[]).filter(el => el.id !== question.id)})
            })
            .catch(err => {
                error("Ups, coś poszło nie tak podczas tworzenia pytania")
                console.error(err);
            })
    }

    function handleSubmitQuestion(question: ITestQuestion) {
        setLoadingBtn2(true)
        updateTestQuestion(data.id, question.id, question)
            .then(({data: resData}) => {
                success("Zaktualizowano pytanie");
                setData({
                    ...data,
                    questions: (data.questions as ITestQuestion[]).map(el => el.id === resData.id ? resData : el)
                })
            })
            .catch(err => {
                error("Ups, coś poszło nie tak podczas tworzenia pytania")
                console.error(err);
            })
            .finally(() => {
                setLoadingBtn2(false)
            })
    }

    function handleAddNewQuestion() {
        createTestQuestion(data.id, q)
            .then(({data: resData}) => {
                success("Utworzono nowe pytanie");
                setData({...data, questions: [...(data.questions as ITestQuestion[]), resData]})
            })
            .catch(err => {
                error("Ups, coś poszło nie tak podczas tworzenia pytania")
                console.error(err);
            })
    }

    if (data == null || data.id === -1) {
        return <Box>
            <Paper>
                <Box sx={{p: 2}}>
                    <LinearProgress/>
                </Box>
            </Paper>
        </Box>
    }

    return <Container>
        <Box mb={4}>
            <Paper>
                {data && <TestForm data={data} setData={setData} onSubmit={handleSubmit} loadingBtn={loadingBtn}/>}
            </Paper>
        </Box>
        {data.blockedAt !== null && (
            <Box mb={4}>
                <Box>
                    <Alert severity="warning">Edycja pytań zablokowana - test został udostępniony uczestnikom</Alert>
                </Box>
            </Box>
        )}
        <Box>
            {(data.questions as ITestQuestion[]).map((question, ix) => <Box
                key={ix}
                mt={1}><TestQuestionForm
                loadingBtn={loadingBtn2}
                name={`Pytanie ${ix + 1}`}
                data={question}
                onSubmit={handleSubmitQuestion}
                onDelete={handleDeleteQuestion}
                blocked={blocked}
            /></Box>)}
            {!blocked && (
                <Box mt={1}><Button onClick={handleAddNewQuestion}>Dodaj nowe pytanie</Button></Box>
            )}
        </Box>
    </Container>
}