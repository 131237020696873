import React, {useState} from "react";
import {
    Box, Paper
} from "@mui/material";
import ElearningForm from "./components/ElearningForm";
import {IElearning} from "../../types/IElearning";
import useAlerts from "../../hooks/useAlerts";
import {useNavigate} from "react-router-dom";
import {R_ELEARNING_EDIT} from "../../config/routes";
import {createElearning} from "../../services/elearning.service";

export default function CreateElearning() {

    const [data, setData] = useState<IElearning>({id: -1, name: "", pages: []});
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    const {error, success} = useAlerts()
    const navigate = useNavigate();

    function handleSubmit(data: IElearning) {
        setLoadingBtn(true)
        createElearning({
            id: -1,
            name: data.name,
            pages: []
        })
            .then(({data}) => {
                navigate(R_ELEARNING_EDIT(data.id));
                success('Szkolenie dodane');
            })
            .catch(err => {
                error('Nie można utworzyć szkolenia');
            })
            .finally(() => {
                setLoadingBtn(false)
            })
    }

    return <Box>
        <Paper>
            <ElearningForm onSubmit={handleSubmit} data={data} setData={setData} loadingBtn={loadingBtn}/>
        </Paper>
    </Box>

}