import PropTypes from "prop-types";
import { Box, Divider, Drawer, Typography, useMediaQuery } from "@mui/material";
import { NavItem } from "./nav-item";
import { APPLICATION_NAME } from "../config/config";
import menu from "../config/menu";
import { Link, useNavigate } from "react-router-dom";
import { R_LOGOUT } from "../config/routes";
import packageJson from "../../package.json";

export const DashboardSidebar = (props) => {
  const navigate = useNavigate();

  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <Box sx={{ px: 2, pt: 2, pb: "3px" }}>
            <Box
              sx={{
                px: 3,
              }}
            >
              <Typography color="inherit" variant="subtitle1">
                {APPLICATION_NAME}
              </Typography>
            </Box>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: "#2D3748",
            my: 2,
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {menu.map((item) => (
            <Link
              to={item.route}
              key={item.name}
              style={{ textDecoration: "none" }}
            >
              <NavItem
                // icon={item.icon}
                href={item.route}
                title={item.name}
              />
            </Link>
          ))}
          <Link style={{ textDecoration: "none" }} to={R_LOGOUT}>
            <NavItem
              // icon={item.icon}
              href={"//"}
              title={"Wyloguj"}
            />
          </Link>
        </Box>
        <Divider sx={{ borderColor: "#2D3748" }} />
        <Box
          sx={{
            px: 2,
            py: 3,
          }}
        >
          <Typography color="neutral.100" variant="subtitle2">
            Owl.IT
          </Typography>
          <Typography color="neutral.500" variant="body2">
            version: {packageJson.version}
          </Typography>
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
