import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import IUser from "../../types/IUser";
import {Box, Container, LinearProgress, Paper, Typography} from "@mui/material";
import UserForm from "./UserForm";
import {getOneUser, updateUser} from "../../services/user.service";
import useAlerts from "../../hooks/useAlerts";
import {R_USER} from "../../config/routes";
import UserPassword from "./UserPassword";
import TrainerGroups from "./TrainerGroups";

export default function EditUser(): JSX.Element {

    const {id} = useParams();
    const navigate = useNavigate();
    const {error, success} = useAlerts()
    const [data, setData] = useState<IUser>();
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    function handleSubmit(data: IUser) {
        if (id) {
            setLoadingBtn(true)
            //TODO: poprawić 'data' wysylane do bazy

            updateUser(id, data)
                .then(() => {
                    success('Zaktualizowano użytkownika');
                    navigate(-1);
                })
                .catch(err => {
                    console.error(err);
                    error('Nie można zaktualizować użytkownika');
                })
                .finally(() => {
                    setLoadingBtn(true)
                })
        }
    }

    useEffect(() => {
        if (id) {
            getOneUser(id)
                .then(({data}) => {
                    setData(data);

                })
                .catch((err) => {
                    console.log(err);
                    navigate(R_USER);
                    error('Nie ma takiego użytkownika');
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (data === undefined) {
        return <Box
        ><Paper>
            <Box sx={{p: 2}}>
                <LinearProgress/>
            </Box>
        </Paper>
        </Box>
    }

    return <Box>
        <Container maxWidth="lg">
            <Typography
                sx={{mb: 3}}
                variant="h4"
            >
                Edycja użytkownika
            </Typography>
            <Paper>
                {data && <UserForm data={data} setData={setData} onSubmit={handleSubmit} loadingBtn={loadingBtn}/>}
            </Paper>
            <Paper sx={{mt: 4, py: 3}}>
                <UserPassword data={data.id as number}/>
            </Paper>
            {data?.role === "Trainer" && <TrainerGroups data={data}/>}
        </Container>
    </Box>
}