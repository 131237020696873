import React, {useEffect, useState} from "react";
import {IElearning} from "../../../types/IElearning";
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    MobileStepper, Radio, RadioGroup,
    Stack, TextField,
    Typography
} from "@mui/material";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {EditorState, convertToRaw, convertFromHTML, ContentState} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import {
    createPage,
    deletePage,
    putPageImage,
    putPageYoutube,
    removePageImage,
    updatePage
} from "../../../services/elearning.service";
import {IElearningPage} from "../../../types/IElearningPage";
import useAlerts from "../../../hooks/useAlerts";
import MediaUploader from "../../../components/MediaUploader";
import IImage from "../../../types/IImage";

export default function ElearningPages({elearning}: {
    elearning: IElearning
}) {

    const {error, success} = useAlerts()

    const [activeStep, setActiveStep] = useState<number>(0);
    const [pages, setPages] = useState<IElearningPage[]>(
        elearning.pages.sort((a, b) => a.index - b.index)
    )

    const steps = pages.length;

    const handleBack = () => setActiveStep(activeStep - 1 > 0 ? activeStep - 1 : 0)
    const handleNext = () => setActiveStep(activeStep + 1 < steps ? activeStep + 1 : steps)

    const handleCreatePage = () => {
        createPage(elearning.id, {})
            .then(({data}) => {
                success("Utworzono strone")
                setPages([...pages, data])
            })
            .catch(err => error("Ups, coś poszło nie tak podczas tworzenia nowej strony"))
    }

    const handleDeletePage = (id: number) => {
        deletePage(elearning.id, id)
            .then(() => {
                success("Pomyślnie usunięto stronę")
                setPages(pages.filter(p => p.id !== id))
            })
            .catch(err => error("Ups, coś poszło nie tak podczas usuwania strony"))
    }

    const handleUpdatePage = (data: IElearningPage) => {
        updatePage(elearning.id, data.id, data)
            .then(({data}) => {
                success("Pomyślnie zaktualizowano stronę")
                setPages(pages.map(p => p.id === data.id ? {media: p.media, ...data} : p).sort((a, b) => a.index - b.index))
            })
            .catch(err => error("Ups, coś poszło nie tak podczas aktualizowania strony"))
    }

    const handleLayoutChange = (data: IElearningPage) => {
        updatePage(elearning.id, data.id, data)
            .then(({data: newData}) => {
                success("Pomyślnie zaktualizowano stronę")
                setPages(pages.map(
                    p => p.id === newData.id ? {...p, layout: newData.layout} : p
                ))
            })
            .catch(err => error("Ups, coś poszło nie tak podczas aktualizowania strony"))
    }

    useEffect(() => {
        setPages(elearning.pages)
    }, [elearning])

    return <Box>
        <MobileStepper
            variant="dots"
            steps={steps}
            position="static"
            activeStep={activeStep}
            // sx={{maxWidth: 400, flexGrow: 1}}
            nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
                    Następna
                    <KeyboardArrowRight/>
                </Button>
            }
            backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    <KeyboardArrowLeft/>
                    Poprzednia
                </Button>
            }
        />
        {activeStep === steps ?
            <NewPage/> :
            <Page
                page={pages[activeStep]}
                onSave={handleUpdatePage}
                onDelete={handleDeletePage}
                onLayoutChange={handleLayoutChange}
            />}
        <MobileStepper
            variant="dots"
            steps={steps}
            position="static"
            activeStep={activeStep}
            // sx={{maxWidth: 400, flexGrow: 1}}
            nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
                    Następna
                    <KeyboardArrowRight/>
                </Button>
            }
            backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    <KeyboardArrowLeft/>
                    Poprzednia
                </Button>
            }
        />
    </Box>


    function NewPage() {
        return <Box pt={5} pb={5} style={{display: 'flex', justifyContent: "center", alignItems: "center"}}>
            <Button onClick={handleCreatePage} size={'large'}>Dodaj strone +</Button>
        </Box>
    }
}


function Page({page, onDelete, onSave, onLayoutChange}: {
    page: IElearningPage,
    onDelete(id: number): void
    onSave(data: IElearningPage): void,
    onLayoutChange(data: IElearningPage): void,
}) {

    const {error, success} = useAlerts()

    const [image, setImage] = useState<IImage>(page.media || {
        id: -1, url: "", type: "",
        key: '/static/images/avatars/avatar_6.png'
    });

    const [index, setIndex] = useState<number>(page.index || 1);
    const [hasChanged, setHasChanged] = useState<boolean>(false);
    const blocksFromHTML = convertFromHTML(page.content || "");
    const [editorState, setEditorState] = React.useState(
        () => EditorState.createWithContent(
            ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            )
        ),
    );

    useEffect(() => {
        setEditorState(EditorState.createWithContent(
            ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            ))
        )

        setImage(page.media || {
            id: -1, url: "", type: "",
            key: '/static/images/avatars/avatar_6.png'
        })

        setIndex(page.index || 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    function handleLayout(layout: number) {
        onLayoutChange({...page, layout})
    }

    return <>
        <Box p={1}>
            <Stack direction={'row-reverse'} spacing={2}>
                <Button onClick={() => {
                    onSave({
                        ...page,
                        index,
                        content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
                    })
                    setHasChanged(false)
                }}>Zapisz</Button>
                <Button onClick={() => onDelete(page.id)} color={'error'}>Usuń</Button>
                {hasChanged && <Typography
                    variant={"caption"}
                    sx={{color: "orange", alignSelf: "center"}}
                >Masz niezapisane zmiany</Typography>}
            </Stack>
        </Box>
        <Box p={1}>
            <Stack direction={'row'} spacing={2}>
                <Box sx={{display: 'flex'}} p={2}>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Pozycja mediów na stronie</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            // defaultValue={0}
                            value={"" + page.layout}
                            name="radio-buttons-group"
                            onChange={(event, value) => handleLayout(+value)}
                        >
                            <FormControlLabel value={0} control={<Radio/>} label="Brak obrazka"/>
                            <FormControlLabel value={1} control={<Radio/>} label="Obrazek u góry"/>
                            <FormControlLabel value={2} control={<Radio/>} label="Obrazek z lewej"/>
                            <FormControlLabel value={3} control={<Radio/>} label="Obrazek z prawej"/>
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box style={{maxWidth: 200,}} p={2}>
                    {page.layout !== 0 && <MediaUploader alt={"elearing-page"}
                                                         image={image}
                                                         setImage={setImage}
                                                         onApply={
                                                             (uri) => {
                                                                 putPageImage(0, page.id, uri)
                                                                     .then(({data}) => {
                                                                         setImage(data);
                                                                         success("Obrazek wgrany")
                                                                     })
                                                                     .catch(() => {
                                                                         error("Ups, coś poszło nie tak podczas zmiany obrazka")
                                                                     })
                                                             }
                                                         }
                                                         onRemove={() => {
                                                             removePageImage(-1, page.id)
                                                                 .then(() => {
                                                                     success("Pomyślnie usunięto obrazek")
                                                                     setImage({
                                                                         id: -1, url: "", type: "",
                                                                         key: '/static/images/avatars/avatar_6.png'
                                                                     })
                                                                 }).catch(err => {
                                                                 error("ups, coś poszło nie tak podczas usuwania obrazka")
                                                             })
                                                         }}
                                                         onYoutube={(url) => {
                                                             putPageYoutube(0, page.id, url)
                                                                 .then(({data}) => {
                                                                     setImage(data);
                                                                     success("Obrazek wgrany")
                                                                 })
                                                                 .catch(() => {
                                                                     error("Ups, coś poszło nie tak podczas zmiany obrazka")
                                                                 })
                                                         }}
                    />}
                </Box>
                <Box style={{maxWidth: 200}} p={2}>
                    <TextField
                        label="Index"
                        onChange={(e) => {
                            if (!hasChanged) setHasChanged(true)
                            setIndex(+e.target.value)
                        }}
                        name="index"
                        value={index || 1}
                        variant={'outlined'}
                        type={'number'}
                        fullWidth
                    />
                </Box>
            </Stack>
        </Box>
        <Box p={0}>
            <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={editorState => {
                    if (!hasChanged) setHasChanged(true)
                    setEditorState(editorState)
                }}
            />
        </Box>
    </>
}