import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import IUser from "../../types/IUser";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import ICompany from "../../types/ICompany";
import { getAllCompanies } from "../../services/company.service";
import useAlerts from "../../hooks/useAlerts";
import { getGroupsParams } from "../../services/group.service";
import IGroup from "../../types/IGroup";
import { paramFilter } from "../../utils/filterUtils";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

export default function UserForm({
  onSubmit,
  data,
  setData,
  loadingBtn,
}: {
  loadingBtn: boolean;
  onSubmit: (data: IUser) => void;
  data: IUser;
  setData:
    | Dispatch<SetStateAction<IUser>>
    | Dispatch<SetStateAction<IUser | undefined>>;
}) {
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [level, setLevel] = useState<number | string>(0);
  const { error } = useAlerts();

  const pathname = window.location.pathname.split("/");
  const newUserFromCompany = !Number.isInteger(
    Number(pathname[pathname.length - 1])
  );
  const hasCompany = () => {
    return data ? (Number(data.company) > 0 ? true : false) : false;
  };

  function startLevel() {
    switch (data.role) {
      case "Company":
        setLevel(10);
        break;
      case "Trainer":
        setLevel(20);
        break;
      case "Admin":
        setLevel(30);
        break;
      default:
        setLevel(0);
        break;
    }
  }

  function getCompanyFromId(sss: Number): ICompany | undefined {
    return companies?.find((x) => x.id === sss);
  }

  function getGroupsFromId(sss: Number[]): IGroup[] {
    if (sss !== undefined) {
      return sss.reduce<IGroup[]>((acc, id) => {
        const x: IGroup | undefined = groups.find((x) => x.id === id);
        if (x !== undefined) acc.push(x);
        return acc;
      }, []);
    } else {
      return [];
    }
  }

  function handleChange(event: React.FormEvent<any>) {
    setData({
      ...data,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  }

  function handleChangeSelect(event: React.SyntheticEvent<any, any>) {
    setData({
      ...data,
      [(event.target as HTMLSelectElement).name]: (
        event.target as HTMLSelectElement
      ).value,
    });

    setLevel((event.target as HTMLSelectElement).value);
  }

  function handleChangeCompany(company: any) {
    setData({ ...data, company: company });

    getGroupsParams(paramFilter("company", company.id))
      .then(({ data }) => {
        setGroups(data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleChangeGroups(groups: any) {
    setData({ ...data, groups: groups.map((x: IGroup) => x.id) });
  }

  function handleSubmit(a: React.FormEvent) {
    onSubmit({ ...data });
  }

  useEffect(() => {
    startLevel();

    getAllCompanies()
      .then(({ data }) => {
        setCompanies(data[0]);
      })
      .catch((err) => {
        setCompanies([]);
        console.error(err);
        error("Nie można pobrać danych");
      });

    if (data.company && (data.company as number) > 0) {
      getGroupsParams(paramFilter("company", data.company))
        .then(({ data }) => {
          setGroups(data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box p={4}>
      <Typography variant="h6" pb={4}>
        Dane
      </Typography>
      <ValidatorForm onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 1,
          }}
          mb={4}
        >
          <TextValidator
            label="Imię i Nazwisko"
            onChange={handleChange}
            name="name"
            value={data.name || ""}
            variant={"standard"}
            validators={["required"]}
            errorMessages={["Pole jest wymagane"]}
            fullWidth
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 1,
          }}
          mb={4}
        >
          <TextValidator
            label="Email"
            onChange={handleChange}
            disabled={data.id !== 0}
            name="email"
            value={data.email || ""}
            variant="standard"
            validators={["required", "isEmail"]}
            errorMessages={[
              "Pole jest wymagane",
              "Pole e-mail nie jest poprawne",
            ]}
            fullWidth
          />
          <TextValidator
            label="Numer telefonu"
            onChange={handleChange}
            name="phone"
            value={data.phone || ""}
            variant="standard"
            validators={[
              "matchRegexp:^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{5,6}$",
            ]}
            helperText="Format +48111222333"
            errorMessages={["Nie poprawny format (np. 048111222333)"]}
            fullWidth
          />
        </Box>
        {newUserFromCompany && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 1,
            }}
            mb={4}
          >
            <FormControl variant="standard">
              <SelectValidator
                label="Poziom uprawnień"
                name="level"
                value={level}
                variant="standard"
                onChange={handleChangeSelect}
                fullWidth
              >
                <MenuItem value={0} key="0">
                  Uczestnicy
                </MenuItem>
                <MenuItem value={10} key="10">
                  Dostęp Firmowy
                </MenuItem>
                <MenuItem value={20} key="20">
                  Egzaminator
                </MenuItem>
                <MenuItem value={30} key="30">
                  Administrator
                </MenuItem>
              </SelectValidator>
            </FormControl>
          </Box>
        )}

        {level === 0 && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 1,
            }}
            mb={4}
            pt={4}
          >
            <Autocomplete
              freeSolo
              id="company"
              disabled={!newUserFromCompany && hasCompany()}
              disableClearable
              onChange={(event, newValue) => {
                if (typeof newValue === "object") {
                  handleChangeCompany(newValue);
                }
              }}
              options={companies ? companies : []}
              getOptionLabel={(option: ICompany): string => {
                return option.name ? option.name : "";
              }}
              value={getCompanyFromId(data.company as Number) || ""}
              renderOption={(props, companies) => {
                return (
                  <li {...props} key={companies.id}>
                    {companies.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Wyszukaj firmę"
                  required={true}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
            <Autocomplete
              multiple
              id="groups"
              noOptionsText={"Brak grup"}
              onChange={(event, newValue) => {
                if (typeof newValue === "object") {
                  handleChangeGroups(newValue);
                }
              }}
              options={groups ? groups : []}
              getOptionLabel={(option: IGroup): string => {
                return option.name ? option.name : "";
              }}
              value={getGroupsFromId(data.groups as Number[]) || ""}
              renderOption={(props, groups) => (
                <li {...props} key={groups.id}>
                  {groups.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Dodaj grupy"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </Box>
        )}

        {level === 10 && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 1,
            }}
            mb={4}
            pt={4}
          >
            <Autocomplete
              freeSolo
              id="company"
              disableClearable
              onChange={(event, newValue) => {
                if (typeof newValue === "object") {
                  handleChangeCompany(newValue);
                }
              }}
              options={companies ? companies : []}
              getOptionLabel={(option: ICompany): string => {
                return option.name ? option.name : "";
              }}
              value={getCompanyFromId(data.company as Number) || ""}
              renderOption={(props, companies) => {
                return (
                  <li {...props} key={companies.id}>
                    {companies.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Wyszukaj firmę"
                  required={true}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </Box>
        )}
        <Grid>
          <Stack direction="row" mt={4} justifyContent="end">
            <LoadingButton
              loading={loadingBtn}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              type={"submit"}
            >
              Zapisz
            </LoadingButton>
          </Stack>
        </Grid>
      </ValidatorForm>
    </Box>
  );
}
