import React, { useEffect, useState } from "react";
import {
    Box, Button, ButtonGroup, LinearProgress, Paper, Typography
} from "@mui/material";
import useAlerts from "../../../hooks/useAlerts";
import { deleteTest } from "../../../services/test.service";
import { Link } from "react-router-dom";
import { R_ELEARNING_EDIT } from "../../../config/routes";
import MUIDataTable from "mui-datatables";
import { IElearning } from "../../../types/IElearning";
import { getElearnings } from "../../../services/elearning.service";


export default function TableElearning() {

    const { error, success } = useAlerts()

    const [muiData, setMuiData] = useState<{ data: IElearning[], count: number }>();

    const [muiSettings] = useState({
        page: 0,
        rowsPerPage: 10,
        sortOrder: {},
    })

    const columns = [
        {
            name: 'id',
            options: {
                display: false,
                filter: false,
                sort: false,
                viewColumns: false
            },
        },
        {
            name: 'name',
            label: 'Nazwa'
        },
        {
            name: 'action',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <Box textAlign="right">
                            <ButtonGroup orientation="horizontal"
                                aria-label="horizontal contained button group"
                                variant="text">
                                <Button component={Link} to={R_ELEARNING_EDIT("" + tableMeta.rowData[0])}
                                    key={tableMeta.rowData[0]}>
                                    Edycja
                                </Button>
                            </ButtonGroup>
                        </Box>
                    )
                }
            }
        },
    ]

    const options = {
        filter: true,
        count: muiData?.count,
        rowsPerPage: muiSettings.rowsPerPage,
        onRowsDelete: (rowsDeleted: any, newData: any) => {
            if (rowsDeleted.data) {
                rowsDeleted.data.map((row: any) => {
                    if (muiData) {
                        return deleteTest(muiData.data[row.dataIndex].id)
                            .then(() => {
                                success('Usunięto szkolenie');
                                return true
                            })
                            .catch(() => {
                                error('Nie można usunąć szkolenia');
                                return false
                            })
                    } else {
                        return false
                    }
                })
            }
        },
        textLabels: {
            body: {
                noMatch: "Brak rekordów",
                toolTip: "Sortuj",
            },
            pagination: {
                next: "Kolejna strona",
                previous: "Poprzednia strona",
                rowsPerPage: "Wierszy na stronę:",
                displayRows: "z",
            },
            toolbar: {
                search: "Szukaj",
                downloadCsv: "Pobierz plik CSV",
                print: "Drukuj",
                viewColumns: "Zobacz kolumny",
                filterTable: "Filtruj tabelę",
            },
            filter: {
                all: "Wszystko",
                title: "FILTRY",
                reset: "RESET",
            },
            viewColumns: {
                title: "Pokaż kolumny",
                titleAria: "Pokaż/Ukryj kolumny",
            },
            selectedRows: {
                text: "Wiersze wybrane",
                delete: "Usuń",
                deleteAria: "Usuń wybrane wiersze",
            },
        },
        download: false,
        print: false
    };


    useEffect(() => {
        getElearnings()
            .then(({ data: a }) => {
                const [data, count] = a;
                setMuiData({ data, count })
            })
            .catch()
    }, [])

    return <Box>
        <Paper>
            {muiData && muiData.count !== 0 && (
                <MUIDataTable
                    title={
                        <Typography variant="h6">
                            Szkolenia
                        </Typography>
                    }
                    columns={columns}
                    options={options}
                    data={muiData.data}
                />
            )}
            {!muiData && (
                <Box sx={{ p: 2 }}>
                    <LinearProgress />
                </Box>
            )}
            {muiData && muiData.count === 0 && (
                <Box sx={{ p: 2 }}>
                    <Typography>Brak szkoleń</Typography>
                </Box>
            )}
        </Paper>
    </Box>

}