import React, {useState} from "react";
import {Box, Container, Paper, Typography} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import {R_TEST_EDIT} from "../../config/routes";
import useAlerts from "../../hooks/useAlerts";
import TestForm from "./components/TestForm";
import {createTest} from "../../services/test.service";
import ITest from "../../types/ITest";

export default function CreateTest(): JSX.Element {

    const [data, setData] = useState<ITest>({
        id: -1,
        name: '',
        blockedAt: false,
        timeOut: 0,
    });
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    const {error, success} = useAlerts()
    const navigate = useNavigate();

    function handleSubmit(data: ITest) {
        setLoadingBtn(true)
        createTest({
            id: -1,
            name: data.name,
            blockedAt: false,
            timeOut: data.timeOut && !isNaN(+data.timeOut) ? +data.timeOut : undefined
        })
            .then(({data}) => {
                navigate(R_TEST_EDIT(data.id));
                success('Test dodany');
            })
            .catch(err => {
                console.error(err);
                error('Nie można utworzyć testu');
            })
            .finally(() => {
                setLoadingBtn(false)
            })
    }

    return <Box>
        <Container maxWidth="lg">
            <Typography
                sx={{mb: 3}}
                variant="h4"
            >
                Nowy test
            </Typography>
            <Paper>
                <TestForm data={data} setData={setData} onSubmit={handleSubmit} loadingBtn={loadingBtn}/>
            </Paper>
        </Container>
    </Box>
}