import React, {Dispatch, SetStateAction} from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";
import IUser from "../../../types/IUser";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator"
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

export default function SettingsForm({
                                         onSubmit,
                                         data,
                                         setData,
                                         loadingBtn
                                     }: { loadingBtn: boolean, onSubmit: (data: IUser) => void, data: IUser, setData: Dispatch<SetStateAction<IUser>> | Dispatch<SetStateAction<IUser | undefined>> }) {

    function handleChange(event: React.FormEvent<any>) {
        setData({
            ...data, [event.currentTarget.name]: event.currentTarget.value
        })
    }

    function handleSubmit(a: React.FormEvent) {
        onSubmit({...data})
    }

    return <Box p={2}>
        <Typography variant="h6" pb={4}>Dane</Typography>
        <ValidatorForm
            onSubmit={handleSubmit}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 1,
                }}
                mb={4}
            >
                <TextValidator
                    label="Imię i Nazwisko"
                    onChange={handleChange}
                    name="name"
                    value={data.name || ""}
                    variant={'outlined'}
                    validators={['required']}
                    errorMessages={['Pole jest wymagane']}
                    fullWidth
                />
                <TextValidator
                    label="Email"
                    name="email"
                    value={data.email}
                    variant='outlined'
                    disabled
                    fullWidth
                    sx={{
                        ml: 4
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 1,
                }}
                mb={4}
            >
                <TextValidator
                    label="Numer telefonu"
                    onChange={handleChange}
                    name="phone"
                    value={data.phone || ""}
                    variant='outlined'
                    validators={['required', 'matchRegexp:^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{5,6}$']}
                    helperText="Format +48111222333"
                    errorMessages={['Pole jest wymagane', 'Nie poprawny format (np. 048111222333)']}
                    fullWidth
                />
            </Box>
            <Grid>
                <Stack direction="row" mt={4} justifyContent="end">
                    <LoadingButton
                        loading={loadingBtn}
                        loadingPosition="start"
                        startIcon={<SaveIcon/>}
                        variant="outlined"
                        type={'submit'}
                    >
                        Zapisz
                    </LoadingButton>
                </Stack>
            </Grid>
        </ValidatorForm>
    </Box>
}