import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { Item } from '../../../types/Item';
import { Draggable } from 'react-beautiful-dnd';

export type DraggableListItemProps = {
    item: Item;
    index: number;
};

const DraggableListItem = ({ item, index }: DraggableListItemProps) => {

    return (
        <Draggable draggableId={item.id} index={index}>
            {(provided: any, snapshot: any) => (
                <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <ListItemAvatar>
                        <Avatar>
                            {index + 1}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.primary} secondary={item.secondary} />
                </ListItem>
            )
            }
        </Draggable >
    );
};

export default DraggableListItem;
