import { Box, SpeedDial, SpeedDialIcon, Tab, Tabs } from "@mui/material";
import React from "react";
import { R_USER_CREATE } from "../../config/routes";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import TableUserStudents from "./tables/TableUserStudents";
import TableUserTrainers from "./tables/TableUserTrainers";
import TableUserCompanies from "./tables/TableUserCompanies";
import TableUserAdmins from "./tables/TableUserAdmins";

export default function User(): JSX.Element {

    const [value, setValue] = React.useState(0);

    const navigate = useNavigate();

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    function TabPanel(props: any) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <>{children}</>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    return <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={4}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Uczestnicy" />
                <Tab label="Dostęp Firmowy" />
                <Tab label="Egzaminatorzy" />
                <Tab label="Administratorzy" />
            </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
            <TableUserStudents />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <TableUserCompanies />
        </TabPanel>
        <TabPanel value={value} index={2}>
            <TableUserTrainers />
        </TabPanel>
        <TabPanel value={value} index={3}>
            <TableUserAdmins />
        </TabPanel>
        <SpeedDial
            ariaLabel="Dodaj użytkownika"
            sx={{ position: 'fixed', bottom: 16, right: 16, cursor: "pointer" }}
            icon={<SpeedDialIcon />}
            onClick={() => navigate(R_USER_CREATE)}
            open={false}
        >
        </SpeedDial>
    </Box >
}