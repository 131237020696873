import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ICompany from "../../types/ICompany";
import { Box, Container, LinearProgress, Paper, Typography } from "@mui/material";
import CompanyForm from "./CompanyForm";
import { getOneCompany, updateCompany } from "../../services/company.service";
import useAlerts from "../../hooks/useAlerts";
import TableCompanyGroups from "./tables/tableCompanyGroups";
import TableCompanyUsers from "./tables/tableCompanyUsers";
import { R_COMPANY } from "../../config/routes";

export default function EditCompany(): JSX.Element {

    const { id } = useParams();
    const [data, setData] = useState<ICompany>();
    const { error, success } = useAlerts()
    const navigate = useNavigate();
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    function handleSubmit(data: ICompany) {
        console.log(data)
        if (id) {
            updateCompany(id, data)
                .then(() => {
                    success('Zaktualizowane dane');
                })
                .catch(err => {
                    console.error(err);
                    error('Nie udało się zaktualizować danych');
                })
        }
    }

    useEffect(() => {
        let isMounted = false;

        if (id) {
            setLoadingBtn(true)

            getOneCompany(id)
                .then(({ data }) => {
                    if (isMounted) return;
                    setData(data)
                })
                .catch(() => {
                    navigate(R_COMPANY);
                    error('Nie ma takiej firmy');
                })
                .finally(() => {
                    setLoadingBtn(false)
                })
        }

        return () => {
            isMounted = true;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (data === undefined) {
        return <Container maxWidth="lg">
            <Typography
                sx={{ mb: 3 }}
                variant="h4"
            >
                Edycja
            </Typography>
            <Paper>
                <Box sx={{ p: 2 }}>
                    <LinearProgress />
                </Box>
            </Paper>
        </Container>
    }

    return <Container maxWidth="lg">
        <Typography
            sx={{ mb: 3 }}
            variant="h4"
        >
            {data.name}
        </Typography>
        {data && <CompanyForm data={data} setData={setData} onSubmit={handleSubmit} loadingBtn={loadingBtn} />}
        <Box p={4}></Box>
        <TableCompanyGroups data={data.id} />
        <Box p={4}></Box>
        <TableCompanyUsers data={data.id} />
        <Box p={4}></Box>
    </Container>
}