import React, { useEffect, useState } from "react";
import {
    Box, Button, ButtonGroup,
    Grid,
    Paper,
    Typography,
    SpeedDial,
    SpeedDialIcon,
    LinearProgress,
} from "@mui/material";
import { R_COMPANY_CREATE, R_COMPANY_EDIT } from "../../../config/routes";
import { Link, useNavigate } from "react-router-dom";
import { deleteCompany, getAllCompanies } from "../../../services/company.service";
import MUIDataTable from "mui-datatables";
import useAlerts from "../../../hooks/useAlerts";
import { ICompanyTables } from "../../../types/ITables";

export default function TableCompanies() {

    const navigate = useNavigate();
    const { error, success, warning } = useAlerts();

    const [muiSettings] = useState({
        page: 0,
        rowsPerPage: 10,
        sortOrder: {},
    })

    const [muiData, setMuiData] = useState<ICompanyTables>({
        count: 0,
        data: [],
    });

    const columns = [
        {
            name: 'id',
            options: {
                display: false,
                filter: false,
                sort: false,
                viewColumns: false
            },
        },
        {
            name: 'name',
            label: 'Nazwa'
        },
        {
            name: 'nip',
            label: 'NIP',
            options: {
                sort: false
            }
        },
        {
            name: 'address',
            label: 'Adres'
        },
        {
            name: 'city',
            label: 'Miasto'
        },
        {
            name: 'postalCode',
            label: 'Kod pocztowy',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'groups',
            label: 'Grupy',
            options: {
                filter: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    return 'Grupy: ' + value.length;
                }
            }
        },
        {
            name: 'action',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <ButtonGroup orientation="horizontal"
                            aria-label="horizontal contained button group"
                            variant="text">
                            <Button sx={{ ml: { xs: 0, sm: 1 } }} component={Link} to={R_COMPANY_EDIT("" + tableMeta.rowData[0])} key={tableMeta.rowData[0]} variant="outlined">
                                Edycja
                            </Button>
                            <Button key={tableMeta.rowData[0] + "_delete"} color="error" onClick={() => handleDeleteCompany(tableMeta.rowData[0])}>
                                Usuń
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        },
    ]

    const options = {
        filter: true,
        count: muiData.count,
        rowsPerPage: muiSettings.rowsPerPage,
        onRowsDelete: (rowsDeleted: any, newData: any) => {
            if (rowsDeleted.data) {
                rowsDeleted.data.map((row: any) => {
                    if (muiData) {
                        return deleteCompany(muiData.data[row.dataIndex].id)
                            .then(() => {
                                success('Usunięto firmę');
                                return true
                            })
                            .catch(() => {
                                error('Nie można usunąć firmy');
                                return false
                            })
                    } else {
                        return false
                    }
                })
            }
        },
        textLabels: {
            body: {
                noMatch: "Brak rekordów",
                toolTip: "Sortuj",
            },
            pagination: {
                next: "Kolejna strona",
                previous: "Poprzednia strona",
                rowsPerPage: "Wierszy na stronę:",
                displayRows: "z",
            },
            toolbar: {
                search: "Szukaj",
                downloadCsv: "Pobierz plik CSV",
                print: "Drukuj",
                viewColumns: "Zobacz kolumny",
                filterTable: "Filtruj tabelę",
            },
            filter: {
                all: "Wszystko",
                title: "FILTRY",
                reset: "RESET",
            },
            viewColumns: {
                title: "Pokaż kolumny",
                titleAria: "Pokaż/Ukryj kolumny",
            },
            selectedRows: {
                text: "Wiersze wybrane",
                delete: "Usuń",
                deleteAria: "Usuń wybrane wiersze",
            },
        },
        download: false,
        print: false,
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: false,
    };

    function handleDeleteCompany(value: number) {
        const dataCompany = muiData.data.find(x => x.id === value);
        console.log('delete ', dataCompany)

        if (dataCompany?.users.length === 0) {
            // sprawdź czy nie ma userów
            if (dataCompany?.groups.length === 0) {
                deleteCompany(dataCompany?.id)
                    .then(() => {
                        success('Usunięto firmę');
                    })
                    .catch(() => {
                        error('Nie można usunąć firmy');
                    })
            } else {
                warning('Usuń najpierw użytkowników aby usunąć firmę')
            }

        } else {
            warning('Usuń najpierw grupy aby usunąć firmę')
        }
    }

    useEffect(() => {
        getAllCompanies()
            .then(({ data }) => {
                setMuiData({ count: data[1], data: data[0] });
            })
            .catch(err => {
                error('Nie można pobrać danych');
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Box>
        {muiData.count !== 0 && (
            <Paper>
                <Grid className={'companyTable'}>
                    <MUIDataTable
                        title={
                            <Typography variant="h6">
                                Firmy
                            </Typography>
                        }
                        data={muiData.data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Paper>
        )}
        {muiData.count === 0 && (
            <Paper>
                <Box sx={{ p: 2 }}>
                    <LinearProgress />
                </Box>
            </Paper>
        )}
        <SpeedDial
            ariaLabel="Dodaj firmę"
            sx={{ position: 'fixed', bottom: 16, right: 16, cursor: "pointer" }}
            icon={<SpeedDialIcon />}
            onClick={() => navigate(R_COMPANY_CREATE)}
            open={false}
        >
        </SpeedDial>
    </Box>

}