import {
    Box
} from "@mui/material";
import React from "react";
import TableCompanies from "./tables/tableCompanies";

export default function Company(): JSX.Element {

    return <Box>
        {<TableCompanies />}
    </Box>

}