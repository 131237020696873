import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {
    Autocomplete,
    Box,
    FormControlLabel,
    Grid,
    LinearProgress,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import ITest from "../../../types/ITest";
import IGroup from "../../../types/IGroup";
import {getGroupsParams} from "../../../services/group.service";
import {paramSort} from "../../../utils/filterUtils";
import {getAllCompanies} from "../../../services/company.service";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

export default function TestForm({
                                     onSubmit,
                                     data,
                                     setData,
                                     loadingBtn
                                 }: {
    loadingBtn: boolean,
    onSubmit: (data: ITest) => void,
    data: ITest,
    setData: Dispatch<SetStateAction<ITest>> | Dispatch<SetStateAction<ITest | undefined>>
}) {

    const [groups, setGroups] = useState<IGroup[]>();
    const [changeSwitch, setChangeSwitch] = useState<boolean>(false);

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChangeSwitch(event.target.checked);
    };

    function handleChange(event: React.FormEvent<any>) {
        setData({
            ...data, [event.currentTarget.name]: event.currentTarget.value
        })
    }

    function handleSubmit(a: React.FormEvent) {
        if (changeSwitch) {
            onSubmit({...data, blockedAt: new Date().toISOString()})
        } else {
            onSubmit({...data})
        }
    }

    ValidatorForm.addValidationRule('isMoreThanZero', (number: string) => {
        return parseInt(number) ? parseInt(number) > 0 ? true : false : false;
    });

    function handleChangeGroups(groups: any) {
        setData({...data, 'groups': groups.map((x: IGroup) => x.id)})
    }

    function getGroupsFromId(sss: Number[]): IGroup[] {
        if (sss !== undefined) {
            return sss.reduce<IGroup[]>((acc, id) => {
                const x: IGroup | undefined = groups?.find(x => x.id === id)
                if (x !== undefined) acc.push(x);
                return acc;
            }, []);
        } else {
            return [];
        }
    }

    useEffect(() => {
        getAllCompanies()
            .then(({data}) => {
                const [companies, count] = data;
                if (companies) {
                    getGroupsParams(paramSort('company'))
                        .then(({data}) => {
                            const [x, count] = data;

                            if (x) {
                                x.map(group => (
                                    group.company = companies?.find(x => x.id === group.company)?.name
                                ))
                            }
                            setGroups(x)

                        })
                        .catch(err => console.error(err))
                }
            })
            .catch(err => console.error(err))
    }, [])

    return <Box
        p={2}
        sx={{
            '& .MuiTextField-root': {m: 1, width: '40ch', mb: 3},
        }}
    >
        {groups && (
            <ValidatorForm
                onSubmit={handleSubmit}
            >
                <Grid container spacing={2}>
                    <Grid item>
                        <TextValidator
                            label="Nazwa"
                            onChange={handleChange}
                            name="name"
                            value={data.name || ""}
                            variant={'standard'}
                            validators={['required']}
                            errorMessages={['Pole jest wymagane']}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <TextValidator
                            label="Limit czasu (min)"
                            onChange={handleChange}
                            name="timeOut"
                            value={data.timeOut || ""}
                            variant={'standard'}
                            validators={['matchRegexp:^[0-9]*$']}
                            errorMessages={['Pole jest wymagane', 'Czas wyrażamy w minutach', 'Czas musi być większy od 0']}
                            fullWidth
                        />
                        <Typography mt={0} mb={0} variant={'caption'}>Jeśli ustawiony zostanie limit czasu to dany wpis
                            stanie się testem!</Typography>
                    </Grid>
                    <Grid item>
                        <TextValidator
                            label="Próg zdawalności (%)"
                            onChange={handleChange}
                            name="whenSucceed"
                            value={data.whenSucceed || 70}
                            variant={'standard'}
                            validators={['matchRegexp:^[0-9]*$']}
                            errorMessages={['Pole jest wymagane', 'Próg musi być większy od 0']}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            multiple
                            id="groups"
                            noOptionsText={'Brak grup'}
                            groupBy={(groups) => groups.company}
                            onChange={(event, newValue) => {
                                if (typeof newValue === "object") {
                                    handleChangeGroups(newValue);
                                }
                            }}
                            options={groups ? groups : []}
                            getOptionLabel={(option: IGroup): string => {
                                return option.name ? option.name : '';
                            }}
                            value={getGroupsFromId(data.groups as unknown as Number[]) || ''}
                            renderOption={(props, groups) => {
                                return (
                                    <li {...props} key={groups.id}>
                                        {groups.name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Dodaj grupy"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                        <Typography mt={0} mb={0} variant={'caption'}>Grupy dla których dostępny będzie
                            test</Typography>
                    </Grid>
                </Grid>
                {data.blockedAt === null && (
                    <Grid container spacing={2} sx={{mt: 4}}>
                        <Grid item>
                            <FormControlLabel control={<Switch checked={changeSwitch} onChange={handleChangeSwitch}/>}
                                              label="Zablokuj edycję"/>
                            <br/>
                            <Typography mt={0} mb={0} variant={'caption'}>Test zostanie udostępniony
                                uczestnikom. <br/><strong>Tego działania nie można cofnąć </strong></Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid>
                    <Stack direction="row" mt={4} justifyContent="end" spacing={2}>
                        <LoadingButton
                            loading={loadingBtn}
                            loadingPosition="start"
                            startIcon={<SaveIcon/>}
                            variant="outlined"
                            type={'submit'}
                        >
                            Zapisz
                        </LoadingButton>
                    </Stack>
                </Grid>
            </ValidatorForm>
        )}
        {!groups && (
            <Box sx={{p: 2}}>
                <LinearProgress/>
            </Box>
        )}
    </Box>

}