import React, {useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import ITestQuestion from "../../../types/ITestQuestion";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Box,
    Button,
    FormControlLabel,
    Grid,
    Switch,
    Typography
} from "@mui/material";
import supportedLanguagesType from "../../../types/supportedLanguagesType";
import IImage from "../../../types/IImage";
import useAlerts from "../../../hooks/useAlerts";
import {putTestQuestionImage, putTestQuestionYoutube, removeTestQuestionImage} from "../../../services/test.service";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import MediaUploader from "../../../components/MediaUploader";

export default function TestQuestionForm({
                                             name,
                                             data,
                                             onSubmit,
                                             onDelete,
                                             blocked,
                                             loadingBtn
                                         }: {
    loadingBtn: boolean,
    name: string
    data: ITestQuestion,
    onSubmit(data: ITestQuestion): void,
    onDelete?(data: ITestQuestion): void,
    blocked: boolean
}) {

    const {error, success} = useAlerts()

    const [changed, setChanged] = useState<boolean>(false);

    const [answer, setAnswer] = useState<string[]>(data?.answer || [])

    const [question, setQuestion] = useState<Record<supportedLanguagesType, string>>(data.question);

    const [answears, setAnswears] = useState<Record<string, Record<supportedLanguagesType, string>>>(data?.answers || {
        "1": {en: "", pl: "", de: ""},
        "2": {en: "", pl: "", de: ""},
        "3": {en: "", pl: "", de: ""},
        "4": {en: "", pl: "", de: ""}
    })

    const [image, setImage] = useState<IImage>(data.media || {
        id: -1, url: "", type: "",
        key: '/static/images/avatars/avatar_6.png'
    });

    function handleSubmit() {
        if (changed === true) setChanged(false);

        onSubmit({
            id: data?.id || -1,
            answer,
            test: data?.test || -1,
            answers: answears,
            question
        })
    }

    function handlePickAnswer(key: string) {
        if (changed === false) setChanged(true);
        if (!answer.includes(key)) {
            setAnswer([...answer, key])
        } else {
            setAnswer(answer.filter(e => e !== key))
        }

    }

    function handleChangeQuestion(e: React.FormEvent<any>) {
        if (changed === false) setChanged(true);
        setQuestion({...question, [e.currentTarget.name]: e.currentTarget.value})
    }

    function handleChangeAnswear(id: string, e: React.FormEvent<any>) {
        if (changed === false) setChanged(true);
        setAnswears({
            ...answears,
            [id]: {...answears[id], [e.currentTarget.name]: e.currentTarget.value}
        })
    }

    return <ValidatorForm
        onSubmit={handleSubmit}
    >
        <Accordion>
            <AccordionSummary>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: "space-between",
                        width: '100%'
                    }}
                >
                    <Typography variant={"overline"}>{name}</Typography>
                    <Typography style={{color: "orange"}}
                                variant={'caption'}>{changed === true && "Niezapisane zmiany"}</Typography>
                    {!blocked && (
                        <div>
                            {onDelete && !!data?.id && (
                                <Button color={'error'} type={'button'} onClick={() => onDelete(data)}>Usuń</Button>
                            )}
                            <LoadingButton
                                disabled={changed === false}
                                loading={loadingBtn}
                                loadingPosition="start"
                                startIcon={<SaveIcon/>}
                                variant="outlined"
                                type={'submit'}
                            >
                                Zapisz pytanie
                            </LoadingButton>
                        </div>
                    )}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    <Grid item md={9} p={2}>
                        <Typography>Pytanie:</Typography>
                        <TextValidator
                            label="Pytanie [PL]"
                            onChange={handleChangeQuestion}
                            name="pl"
                            value={question.pl || ""}
                            variant={'standard'}
                            validators={['required']}
                            errorMessages={['Pole jest wymagane']}
                            fullWidth
                            size={'small'}
                            // sx={{pb: 2}}
                        />
                        <TextValidator
                            label="Pytanie [EN]"
                            onChange={handleChangeQuestion}
                            name="en"
                            value={question.en || ""}
                            variant={'standard'}
                            fullWidth
                            size={'small'}
                            // sx={{pb: 2}}
                        />
                        <TextValidator
                            label="Pytanie [DE]"
                            onChange={handleChangeQuestion}
                            name="de"
                            value={question.de || ""}
                            variant={'standard'}
                            fullWidth
                            size={'small'}
                            // sx={{pb: 2}}
                        />
                    </Grid>
                    <Grid item md={3} p={2}>
                        {/*<Typography>Obrazek:</Typography>*/}
                        <MediaUploader
                            alt={name}
                            image={image}
                            setImage={setImage}
                            onApply={
                                (uri) => {
                                    putTestQuestionImage(0, data.id, uri)
                                        .then(({data}) => {
                                            setImage(data);
                                            success("Obrazek wgrany")
                                        })
                                        .catch(() => {
                                            error("Ups, coś poszło nie tak podczas zmiany obrazka")
                                        })
                                }
                            }
                            onRemove={() => {
                                removeTestQuestionImage(-1, data.id)
                                    .then(() => {
                                        success("Pomyślnie usunięto obrazek")
                                        setImage({
                                            id: -1, url: "", type: "",
                                            key: '/static/images/avatars/avatar_6.png'
                                        })
                                    }).catch(err => {
                                    error("ups, coś poszło nie tak podczas usuwania obrazka")
                                })
                            }}
                            onYoutube={(url) => {
                                putTestQuestionYoutube(0, data.id, url)
                                    .then(({data}) => {
                                        setImage(data);
                                        success("Obrazek wgrany")
                                    })
                                    .catch(() => {
                                        error("Ups, coś poszło nie tak podczas zmiany obrazka")
                                    })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} p={2}>
                        <Typography>Odpowiedzi:</Typography>
                        {Object.entries(answears).map(([key, values], ix) =>
                            <Answear
                                onSelect={() => handlePickAnswer(key)}
                                answer={answer.includes(key)}
                                key={ix}
                                name={`Odpowiedź ${key}`}
                                onChange={(e) => handleChangeAnswear(key, e)}
                                data={values}
                                blocked={blocked}
                            />
                        )}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </ValidatorForm>
}

function Answear(
    {
        name,
        data,
        answer,
        onChange,
        onSelect,
        blocked
    }: {
        name: string,
        answer: boolean,
        data: Record<supportedLanguagesType, string>,
        onSelect(e: React.ChangeEvent): void,
        onChange(e: React.FormEvent): void,
        blocked: boolean
    }
) {
    return <Accordion>
        <AccordionSummary>
            <Box
                sx={{
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: "space-between",
                    width: '100%'
                }}
            >
                <Typography variant={"overline"}>{name}</Typography>
                <FormControlLabel control={
                    <Switch
                        onChange={onSelect}
                        checked={answer}
                        style={{pointerEvents: 'none'}}
                        disabled={blocked}
                    />
                }
                                  label="Poprawna odpowiedź"
                                  labelPlacement="start"
                />
            </Box>
        </AccordionSummary>
        <AccordionDetails>
            <TextValidator
                label={`${name} [PL]`}
                onChange={onChange}
                name="pl"
                value={data.pl || ""}
                variant={'standard'}
                validators={[]}
                errorMessages={['Pole jest wymagane']}
                fullWidth
                size={'small'}
                // sx={{pb: 2}}
            />
            <TextValidator
                label={`${name} [EN]`}
                onChange={onChange}
                name="en"
                value={data.en || ""}
                variant={'standard'}
                validators={[]}
                errorMessages={['Pole jest wymagane']}
                fullWidth
                size={'small'}
                // sx={{pb: 2}}
            />
            <TextValidator
                label={`${name} [DE]`}
                onChange={onChange}
                name="de"
                value={data.de || ""}
                variant={'standard'}
                validators={[]}
                errorMessages={['Pole jest wymagane']}
                fullWidth
                size={'small'}
                // sx={{pb: 2}}
            />
        </AccordionDetails>
    </Accordion>
}
