import React, {useState} from "react";
import IGroup from "../../types/IGroup";
import {Box, Container, LinearProgress, Paper, Typography} from "@mui/material";
import GroupForm from "./GroupForm";
import {createGroup} from "../../services/group.service";
import {useNavigate, useParams} from 'react-router-dom';
import {R_COMPANY_EDIT, R_GROUP} from "../../config/routes";
import useAlerts from "../../hooks/useAlerts";

export default function CreateGroup(): JSX.Element {

    const {id} = useParams();

    const {error, success} = useAlerts()
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    // const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, '-');

    const [data, setData] = useState<IGroup>({
        id: 0,
        name: "",
        company: Number(id)
    });

    const navigate = useNavigate();

    function handleSubmit(data: IGroup) {
        setLoadingBtn(true)
        createGroup(data)
            .then(({data}) => {
                if (id) {
                    navigate(R_COMPANY_EDIT(id))
                } else {
                    navigate(R_GROUP)
                }
                success('Utworzono poprawnie grupę');
            })
            .catch(err => {
                console.error(err);
                if (id) {
                    navigate(R_COMPANY_EDIT(id))
                }
                error('Nie można utworzyć grupy');
            })
            .finally(() => {
                setLoadingBtn(false)
            })
    }

    if (data === undefined) {
        return <Box>
            <Paper>
                <Box sx={{p: 2}}>
                    <LinearProgress/>
                </Box>
            </Paper>
        </Box>
    }

    return <Box>
        <Container maxWidth="lg">
            <Typography
                sx={{mb: 3}}
                variant="h4"
            >
                Nowa grupa
            </Typography>
            <Paper>
                {data && <GroupForm data={data} setData={setData} onSubmit={handleSubmit} loadingBtn={loadingBtn}/>}
            </Paper>
        </Container>
    </Box>
}