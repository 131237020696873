import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {AccordionDetails, Box, Button, Divider, Grid, ListItem, Paper, Stack, Typography} from "@mui/material";
import ICardItem from "../../../types/ICardItem";
import useAlerts from "../../../hooks/useAlerts";
import {updateCardItem} from "../../../services/card.service";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import CardGroupRaportTexts from "./CardGroupRaportTexts";

export default function CardItem({
                                     data,
                                     onDelete,
                                     groupId,
                                     blocked
                                 }: {
    data: ICardItem,
    onDelete(itemId: number): void
    groupId: number,
    blocked: boolean
}) {

    const [item, setItem] = useState<ICardItem>(data)
    const {error, success} = useAlerts()
    const [change, setChange] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    function handleChange(event: React.FormEvent<any>) {
        setItem({...item, [event.currentTarget.name]: event.currentTarget.value})
        setChange(true);
    }

    function handleChangeName(lang: string) {
        return (event: React.FormEvent<any>) => {
            setItem({...item, name: {...item.name, [lang]: event.currentTarget.value}})
            setChange(true);
        }
    }

    function handleSubmit(e: React.FormEvent) {
        if (item && item.id) {
            setLoadingBtn(true)

            updateCardItem(item.id, item, groupId)
                .then(({data}) => {
                    setItem(data)
                    success('Zaktualizowane dane');
                    setChange(false);
                })
                .catch(err => {
                    console.error(err);
                    error('Nie udało się zaktualizować danych');
                })
                .finally(() => {
                    setLoadingBtn(false)
                })
        }
    }

    useEffect(() => { // usuwa bug przy usuwaniu xD
        setItem(data)
    }, [data])

    return <ListItem dense>
        <ValidatorForm
            style={{width: "100%"}}
            onSubmit={handleSubmit}
        >
            <Paper variant={'outlined'}>
                <Box p={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant={"caption"}>Treść pytania</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextValidator
                                label="Pytanie PL"
                                onChange={handleChangeName('pl')}
                                name="name"
                                size={'small'}
                                value={item.name['pl'] || ""}
                                variant={'outlined'}
                                validators={['required']}
                                errorMessages={['Pole jest wymagane']}
                                fullWidth
                            />
                            <TextValidator
                                label="Pytanie EN"
                                onChange={handleChangeName('en')}
                                name="name"
                                size={'small'}
                                value={item.name['en'] || ""}
                                variant={'outlined'}
                                validators={['required']}
                                errorMessages={['Pole jest wymagane']}
                                fullWidth
                            />
                            <TextValidator
                                label="Pytanie DE"
                                onChange={handleChangeName('de')}
                                name="name"
                                size={'small'}
                                value={item.name['de'] || ""}
                                variant={'outlined'}
                                validators={['required']}
                                errorMessages={['Pole jest wymagane']}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextValidator
                                label="Punkty"
                                onChange={handleChange}
                                name="points"
                                value={item.points || "1"}
                                variant={'outlined'}
                                validators={['required']}
                                type={'number'}
                                errorMessages={['Pole jest wymagane']}
                                fullWidth
                            />
                            <Stack width={'100%'} direction={'row-reverse'}>
                                <Button
                                        color={'error'}
                                        type={'button'}
                                        disabled={blocked}
                                        onClick={() => onDelete(item.id)}>Usuń</Button>
                                <LoadingButton
                                    disabled={!change}
                                    loading={loadingBtn}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon/>}
                                    variant="outlined"
                                    size={'small'}
                                    type={'submit'}
                                >
                                    {change ? 'Zapisz' : 'Brak zmian'}
                                </LoadingButton>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <CardGroupRaportTexts data={item.marks} itemId={item.id} blocked={blocked}/>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </ValidatorForm>
    </ListItem>
}