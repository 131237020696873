import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {Button, Divider, Grid, ListItem, Stack} from "@mui/material";
import ICardItem from "../../../types/ICardItem";
import useAlerts from "../../../hooks/useAlerts";
import {updateCardMark} from "../../../services/card.service";
import ICardMark from "../../../types/ICardMark";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

export default function CardGroupReportTextItem({
                                                    data,
                                                    onDelete,
                                                    itemId,
                                                    blocked
                                                }: {
    data: ICardMark,
    onDelete(itemId: number): void
    itemId: number,
    blocked: boolean
}) {

    const [mark, setMark] = useState<ICardMark>(data)
    const [change, setChange] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    const {error, success} = useAlerts()

    function handleChange(event: React.FormEvent<any>) {
        setMark({...mark, [event.currentTarget.name]: event.currentTarget.value})
        setChange(true);
    }

    function handleChangeName(lang: string) {
        return (event: React.FormEvent<any>) => {
            setMark({...mark, name: {...mark.name, [lang]: event.currentTarget.value}})
            setChange(true);
        }
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault()
        e.stopPropagation()
        if (mark && mark.id) {
            setLoadingBtn(true)

            updateCardMark(mark.id, mark, itemId)
                .then(({data}) => {
                    setMark(data)
                    success('Zaktualizowane dane');
                    setChange(false);
                })
                .catch(err => {
                    console.error(err);
                    error('Nie udało się zaktualizować danych');
                })
                .finally(() => {
                    setLoadingBtn(false)
                })
        }
    }

    useEffect(() => { // usuwa bug przy usuwaniu xD
        setMark(data)
    }, [data])

    return <ListItem dense>
        <ValidatorForm
            style={{width: "100%"}}
            onSubmit={handleSubmit}
        >
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <TextValidator
                        label="od ilu punktów"
                        onChange={handleChange}
                        name="points"
                        value={mark.points || ""}
                        variant={'outlined'}
                        validators={['required']}
                        type={'number'}
                        errorMessages={['Pole jest wymagane']}
                        fullWidth
                    />
                        <Stack width={'100%'} direction={'row-reverse'}>
                            <Button color={'error'} type={'button'} disabled={blocked} onClick={() => onDelete(mark.id)}>Usuń</Button>
                            <LoadingButton
                                disabled={!change}
                                loading={loadingBtn}
                                loadingPosition="start"
                                startIcon={<SaveIcon/>}
                                variant="outlined"
                                onClick={handleSubmit}
                                type={'button'}
                            >
                                {change ? 'Zapisz' : 'Brak zmian'}
                            </LoadingButton>
                        </Stack>
                </Grid>
                <Grid item xs={10}>
                    <TextValidator
                        label="Odpowiedź PL"
                        onChange={handleChangeName('pl')}
                        name="name"
                        multiline={true}
                        // rows={4}
                        value={mark.name['pl'] || ""}
                        variant={'outlined'}
                        validators={['required']}
                        errorMessages={['Pole jest wymagane']}
                        fullWidth
                        size={'small'}
                    />
                    <TextValidator
                        label="Odpowiedź EN"
                        onChange={handleChangeName('en')}
                        name="name"
                        multiline={true}
                        // rows={4}
                        value={mark.name['en'] || ""}
                        variant={'outlined'}
                        validators={['required']}
                        errorMessages={['Pole jest wymagane']}
                        fullWidth
                        size={'small'}
                    />
                    <TextValidator
                        label="Odpowiedź DE"
                        onChange={handleChangeName('de')}
                        name="name"
                        multiline={true}
                        // rows={4}
                        value={mark.name['de'] || ""}
                        variant={'outlined'}
                        validators={['required']}
                        errorMessages={['Pole jest wymagane']}
                        fullWidth
                        size={'small'}
                    />
                </Grid>
            </Grid>
        </ValidatorForm>
    </ListItem>
}