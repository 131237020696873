import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import { R_GROUP_EDIT, R_GROUP_FROM_COMPANY_CREATE } from "../../../config/routes";
import useAlerts from "../../../hooks/useAlerts";
import { deleteGroup, getGroupsParams, getOneGroup } from "../../../services/group.service";
import IGroup from "../../../types/IGroup";
import { paramFilter } from "../../../utils/filterUtils";
import DateAdapter from '@mui/lab/AdapterMoment';
import { createEvent } from "../../../services/event.service";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

export default function TableCompanyGroups(data: any) {

    const id = data.data;
    const { error, success } = useAlerts()

    const [promptOpen, setPromptOpen] = useState(false);
    const [promptTarget, setPromptTarget] = useState<string | number>();
    const [groups, setGroups] = useState<IGroup[]>();
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    const [modalEvent, setModalEvent] = useState<boolean>(false);
    const [pickGroup, setPickGroup] = useState<IGroup>()

    const [eventData, setEventData] = useState<any>();

    function handleChange(event: React.FormEvent<any>) {
        setEventData({ ...eventData, [event.currentTarget.name]: event.currentTarget.value })
    }

    function handleChangeSelect(event: SelectChangeEvent) {
        setEventData({ ...eventData, color: event.target.value as string })
    };

    function handleSubmit() {

        const dataToSend = {
            group: pickGroup?.id,
            startTime: eventData?.startTime.toISOString(),
            endTime: eventData?.endTime.toISOString(),
            name: eventData?.name,
            color: eventData?.color
        }

        setLoadingBtn(true)

        createEvent(dataToSend)
            .then((data) => {
                success("Utworzono wydarzenie")
                setModalEvent(false);

                if (pickGroup?.id) {
                    getOneGroup(pickGroup?.id)
                        .then(({ data }) => {
                            if (groups) {
                                const foundIndex = groups.findIndex(x => x.id === pickGroup?.id)
                                const newGroups = groups
                                newGroups[foundIndex] = data;
                                setGroups(newGroups)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            error()
                        })
                }

                setEventData({})

            })
            .catch((err) => {
                console.error(err)
                error()
            })
            .finally(() => {
                setLoadingBtn(false)
            })

    }

    function handlePrompt(open: boolean, id?: string | number) {
        if (id) {
            setPromptTarget(id);
            setPromptOpen(open);
        } else {
            setPromptOpen(open);
        }
    }

    function handleDelete(id: string | number) {

        handlePrompt(false);

        deleteGroup(id)
            .then(() => {
                setGroups(groups => groups?.filter(({ id: cId }) => id !== cId))
                success('Usunięto grupę')
            })
            .catch(err => {
                console.error(err);
                error('Nie można usunąć grupy');
            })
    }


    useEffect(() => {
        if (id) {
            getGroupsParams(paramFilter('company', id))
                .then(({ data }) => {
                    // setGroups(data[0])

                    const groupsData = data[0]

                    groupsData?.map((group, index) => {
                        if (group.id) {

                            getOneGroup(group.id)
                                .then(({ data }) => {
                                    groupsData[index] = data;
                                    setGroups(groupsData)
                                })
                                .catch((err) => {
                                    console.log(err)
                                    error()
                                })

                            return false

                        } else {
                            return false
                        }
                    })

                })
                .catch(err => {
                    setGroups([])
                    console.error(err);
                    error();
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return <Box
        pt={12}
        pb={2}
        sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
    >
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                m: -1
            }}
            pb={4}
        >
            <Typography
                sx={{ m: 1 }}
                variant="h5"
            >
                Grupy
            </Typography>
            <Box sx={{ m: 1 }}>
                <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to={R_GROUP_FROM_COMPANY_CREATE(id)}
                >
                    Dodaj grupę
                </Button>
            </Box>
        </Box>
        <Paper>
            {!groups && (
                <Box p={2}>
                    <LinearProgress />
                </Box>
            )}
            {groups && Object.keys(groups).length === 0 && (
                <Box p={2}>
                    <Typography>Brak grup</Typography>
                </Box>
            )}
            {groups && Object.keys(groups).length !== 0 && (
                <Grid container>
                    <Grid item style={{ width: '100%', whiteSpace: 'nowrap' }}>
                        <TableContainer>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nazwa</TableCell>
                                        <TableCell>Wydarzenia</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groups.map((group, index) =>
                                        <TableRow key={index} hover>
                                            <TableCell>
                                                {group.name}
                                            </TableCell>
                                            <TableCell>
                                                {group.events.length === 0 && (
                                                    <>
                                                        Brak wydarzeń
                                                    </>
                                                )}
                                                {group.events.length !== 0 && group.events.map((event: any, index: number) => (
                                                    <p key={event.id}>
                                                        {(index + 1) === group.events.length ? event.name : event.name + ','}
                                                    </p>
                                                ))}
                                            </TableCell>
                                            <TableCell align="right">
                                                <ButtonGroup orientation="horizontal"
                                                    aria-label="horizontal contained button group"
                                                    variant="text">
                                                    <Button key="add_event" onClick={() => { setModalEvent(true); setPickGroup(group) }}>
                                                        Dodaj wydarzenie
                                                    </Button>
                                                    <Button component={Link} to={R_GROUP_EDIT("" + group.id)} key="edit">
                                                        Edycja
                                                    </Button>
                                                    <Button key="remove" onClick={() => handlePrompt(true, group.id)}>Usuń</Button>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            )}
        </Paper>
        <Dialog
            open={promptOpen}
            onClose={() => handlePrompt(false)}
        >
            <DialogTitle>
                Usunąć wybraną grupę ?
            </DialogTitle>
            <DialogActions>
                <Button onClick={() => handlePrompt(false)}>Nie</Button>
                <Button onClick={() => promptTarget ? handleDelete(promptTarget) : ''} autoFocus>Tak</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={modalEvent} onClose={() => { setModalEvent(false); setEventData({}) }}>
            <ValidatorForm
                onSubmit={handleSubmit}
            >
                <DialogTitle>
                    Dodaj wydarzenie {pickGroup ? 'do grupy ' + pickGroup.name : ''}
                </DialogTitle>
                <DialogContent dividers>

                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(1, 1fr)',
                            gap: 5,
                        }}
                        mb={2}

                    >
                        <TextValidator
                            label="Nazwa wydarzenia"
                            onChange={handleChange}
                            name="name"
                            value={eventData?.name || ''}
                            variant={'standard'}
                            validators={['required']}
                            errorMessages={['Pole jest wymagane']}
                            fullWidth
                        />
                    </Box>
                    <LocalizationProvider dateAdapter={DateAdapter} locale={'plLocale'}>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                gap: 5,
                            }}
                            mb={4}
                            pt={4}
                        >
                            <DateTimePicker
                                label="Data rozpoczęcia"
                                value={eventData?.startTime || ''}
                                onChange={(value) => {
                                    setEventData({ ...eventData, startTime: value })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DateTimePicker
                                label="Data zakończenia"
                                value={eventData?.endTime || ''}
                                onChange={(value) => {
                                    setEventData({ ...eventData, endTime: value })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Box>
                    </LocalizationProvider>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(1, 1fr)',
                            gap: 5,
                        }}
                        mb={2}

                    >
                        <FormControl fullWidth>
                            <InputLabel id="label_color">Rodzaj</InputLabel>
                            <Select
                                labelId="label_color"
                                id="color"
                                value={eventData?.color || ''}
                                label="Kolor"
                                onChange={handleChangeSelect}
                            >
                                <MenuItem value={'#f5655b'}>Egzamin</MenuItem>
                                <MenuItem value={'#0ACF83'}>Jazda</MenuItem>
                                <MenuItem value={'#29ffac'}>Szkolenie</MenuItem>
                                <MenuItem value={'#F44336'}>Inne</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => { setModalEvent(false); setEventData({}) }}>Wróć</Button>
                        <LoadingButton
                            loading={loadingBtn}
                            loadingPosition="start"
                            startIcon={<SaveIcon/>}
                            variant="outlined"
                            type={'submit'}
                        >
                            Zapisz
                        </LoadingButton>
                    </DialogActions>
                </DialogActions>
            </ValidatorForm>
        </Dialog>

    </Box>

}