import React, { useEffect, useState } from "react";
import {
    Box, Button, ButtonGroup, Grid, LinearProgress, Paper, Typography
} from "@mui/material";
import useAlerts from "../../../hooks/useAlerts";
import { deleteTest, getTests } from "../../../services/test.service";
import { Link } from "react-router-dom";
import { ITestTables } from "../../../types/ITables";
import { R_TEST_EDIT } from "../../../config/routes";
import MUIDataTable from "mui-datatables";


export default function TableTest() {

    const { error, info, success } = useAlerts()

    const [muiData, setMuiData] = useState<ITestTables>({
        count: 0,
        data: [],
    });


    const [muiSettings] = useState({
        page: 0,
        rowsPerPage: 10,
        sortOrder: {},
    })

    const columns = [
        {
            name: 'id',
            options: {
                display: false,
                filter: false,
                sort: false,
                viewColumns: false
            },
        },
        {
            name: 'name',
            label: 'Nazwa'
        },
        {
            name: "typ",
            label: "Typ"
        },
        {
            name: "blockedAt",
            label: "Edycja",
            options: {
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <Typography>
                            {tableMeta.rowData[3] ? 'Zablokowana' : 'Otwarta'}
                        </Typography>
                    )
                }
            }
        },
        {
            name: 'action',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <Box textAlign="right">
                            <ButtonGroup orientation="horizontal"
                                aria-label="horizontal contained button group"
                                variant="text">
                                <Button component={Link} to={R_TEST_EDIT("" + tableMeta.rowData[0])}
                                    key={tableMeta.rowData[0]}>
                                    Edycja
                                </Button>
                            </ButtonGroup>
                        </Box>
                    )
                }
            }
        },
    ]

    const options = {
        filter: true,
        count: muiData.count,
        rowsPerPage: muiSettings.rowsPerPage,
        onRowsDelete: (rowsDeleted: any, newData: any) => {
            if (rowsDeleted.data) {
                rowsDeleted.data.map((row: any) => {
                    if (muiData.data[row.dataIndex]?.blockedAt === null) {
                        return deleteTest(muiData.data[row.dataIndex].id)
                            .then(() => {
                                success('Usunięto test');
                                return true
                            })
                            .catch(() => {
                                error('Nie można usunąć testu');
                                return false
                            })
                    } else {
                        info("Nie można usunąć zablokowanego testu")
                        return false
                    }
                })
            }
        },
        textLabels: {
            body: {
                noMatch: "Brak rekordów",
                toolTip: "Sortuj",
            },
            pagination: {
                next: "Kolejna strona",
                previous: "Poprzednia strona",
                rowsPerPage: "Wierszy na stronę:",
                displayRows: "z",
            },
            toolbar: {
                search: "Szukaj",
                downloadCsv: "Pobierz plik CSV",
                print: "Drukuj",
                viewColumns: "Zobacz kolumny",
                filterTable: "Filtruj tabelę",
            },
            filter: {
                all: "Wszystko",
                title: "FILTRY",
                reset: "RESET",
            },
            viewColumns: {
                title: "Pokaż kolumny",
                titleAria: "Pokaż/Ukryj kolumny",
            },
            selectedRows: {
                text: "Wiersze wybrane",
                delete: "Usuń",
                deleteAria: "Usuń wybrane wiersze",
            },
        },
        download: false,
        print: false
    };


    useEffect(() => {
        getTests()
            .then(({ data }) => {
                setMuiData({ count: data[1], data: data[0] });
            })
            .catch(err => {
                error();
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Box>
        {muiData.count !== 0 && (
            <Paper>
                <Grid>
                    <MUIDataTable
                        title={
                            <Typography variant="h6">
                                Testy
                            </Typography>
                        }
                        columns={columns}
                        options={options}
                        data={muiData.data.map((el: any) => {
                            el.typ = el.timeOut && el.timeOut > 0 ? "Test" : "Quiz";
                            return el;
                        })}
                    />
                </Grid>
            </Paper>
        )}
        {muiData.count === 0 && (
            <Paper>
                <Box sx={{ p: 2 }}>
                    <LinearProgress />
                </Box>
            </Paper>
        )}
    </Box>

}