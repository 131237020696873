import React, {useState} from "react";
import IUser from "../../types/IUser";
import {
    Box,
    Button,
    Container, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Typography
} from "@mui/material";
import UserForm from "./UserForm";
import {createUser} from "../../services/user.service";
import {useNavigate, useParams} from 'react-router-dom';
import useAlerts from "../../hooks/useAlerts";

export default function CreateUser(): JSX.Element {

    const params = useParams().id;

    const [data, setData] = useState<IUser>({
        id: 0,
        company: idFromParams()
    });

    const [result, setResult] = useState<IUser>()
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    const {error, success} = useAlerts()
    const navigate = useNavigate();

    function idFromParams() {
        if (params !== undefined) {
            return Number(params);
        } else {
            return 0;
        }
    }

    function handleSubmit(data: any) {
        setLoadingBtn(true)
        switch (data.level) {
            case 30:

                data.role = "Admin"
                if (data.company !== undefined) {
                    delete data.company;
                }
                if (data.groups !== undefined) {
                    delete data.groups;
                }

                break;
            case 20:

                data.role = "Trainer"
                if (data.company !== undefined) {
                    delete data.company;
                }
                if (data.groups !== undefined) {
                    delete data.groups;
                }

                break;
            case 10:

                data.role = "Company"

                if (data.groups !== undefined) {
                    delete data.groups;
                }

                if (typeof data.company === 'object' && data.company !== null) {
                    data.company = data.company.id
                }

                break;
            default:

                data.role = "User"

                if (typeof data.company === 'object' && data.company !== null) {
                    data.company = data.company.id
                }

                break;

        }

        delete data.level;
        delete data.id;

        createUser(data)
            .then(({data}) => {
                // if (idFromParams()) {
                //     navigate(R_COMPANY_EDIT(idFromParams()))
                // } else {
                //     navigate(R_USER_EDIT(data.id))
                // }
                // navigate(-1);
                success('Utworzono użytkownika')
                setResult(data)
            })
            .catch(err => {
                console.error(err);
                if (err?.response?.status === 400 && err?.response?.data?.detail.includes('already exists')) {
                    error('Email wykorzystany');
                } else {
                    error('Nie można utworzyć użytkownika');
                }
            })
            .finally(() => {
                setLoadingBtn(false)
            })
    }

    function handleClose() {
        navigate(-1);
        setResult(undefined)
    }

    return <Box>
        <Container maxWidth="lg">
            <Dialog
                open={!!result}
                onClose={handleClose}
            >
                <DialogTitle>
                    Dane użytkownika
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Zapisz hasło użytkownika: <strong>{result?.password}</strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Typography
                sx={{mb: 3}}
                variant="h4"
            >
                Nowy użytkownik
            </Typography>
            <Paper>
                <UserForm data={data} setData={setData} onSubmit={handleSubmit} loadingBtn={loadingBtn}/>
            </Paper>
        </Container>
    </Box>
}