import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import ICompany from "../../types/ICompany";
import CompanyProfileImage from "./components/CompanyProfileImage";
import IImage from "../../types/IImage";
import { putCompanyImage } from "../../services/company.service";
import useAlerts from "../../hooks/useAlerts";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

export default function CompanyForm({
  onSubmit,
  data,
  setData,
  loadingBtn,
}: {
  loadingBtn: boolean;
  onSubmit: (data: ICompany, file?: File) => void;
  data: ICompany;
  setData:
    | Dispatch<SetStateAction<ICompany>>
    | Dispatch<SetStateAction<ICompany | undefined>>;
}) {
  const { error, success } = useAlerts();

  const [image, setImage] = useState<IImage>(
    data.logo || {
      id: -1,
      url: "",
      type: "",
      key: "/static/images/avatars/avatar_6.png",
    }
  );

  function handleChange(event: React.FormEvent<any>) {
    setData({ ...data, [event.currentTarget.name]: event.currentTarget.value });
  }

  function handleSubmit(a: React.FormEvent) {
    onSubmit({ ...data });
  }

  function handleFileChange(file: File) {
    putCompanyImage(data.id, file)
      .then(({ data }) => {
        setImage(data);
        success("Obrazek zmieniony");
      })
      .catch(() => {
        error("Ups, coś poszło nie tak podczas zmiany obrazka");
      });
  }

  ValidatorForm.addValidationRule("isPolishTaxNumber", (nip) => {
    const nipWithoutDashes = nip.replace(/-/g, "");
    const reg = /^[0-9]{10}$/;

    if (reg.test(nipWithoutDashes) === false) {
      return false;
    } else {
      const digits = ("" + nipWithoutDashes).split("");
      const checksum =
        (6 * parseInt(digits[0]) +
          5 * parseInt(digits[1]) +
          7 * parseInt(digits[2]) +
          2 * parseInt(digits[3]) +
          3 * parseInt(digits[4]) +
          4 * parseInt(digits[5]) +
          5 * parseInt(digits[6]) +
          6 * parseInt(digits[7]) +
          7 * parseInt(digits[8])) %
        11;

      return parseInt(digits[9]) === checksum;
    }
  });

  return (
    <Box>
      <ValidatorForm
        onSubmit={handleSubmit}
        // onError={errors => console.log(errors)}
        sx={{ mb: 4 }}
      >
        <Grid container spacing={3}>
          {data.id !== 0 && (
            <Grid item lg={4} md={6} xs={12}>
              <CompanyProfileImage onFile={handleFileChange} image={image} />
            </Grid>
          )}
          <Grid item lg={8} md={6} xs={12}>
            <Card>
              <CardHeader subheader="" title="Szczegóły" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextValidator
                      fullWidth
                      label="Nazwa firmy"
                      onChange={handleChange}
                      name="name"
                      value={data.name || ""}
                      variant={"outlined"}
                      validators={
                        [
                          //"required"
                        ]
                      }
                      errorMessages={
                        [
                          //"Pole jest wymagane"
                        ]
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextValidator
                      fullWidth
                      label="NIP"
                      onChange={handleChange}
                      name="nip"
                      value={data.nip || ""}
                      variant="outlined"
                      validators={["isPolishTaxNumber"]}
                      errorMessages={[
                        // "Pole jest wymagane",
                        "Nie prawidłowy nr NIP",
                      ]}
                      helperText="Format 0000000000"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextValidator
                      fullWidth
                      label="Adres"
                      onChange={handleChange}
                      name="address"
                      value={data.address || ""}
                      variant="outlined"
                      validators={
                        [
                          //"required"
                        ]
                      }
                      errorMessages={
                        [
                          //"Pole jest wymagane"
                        ]
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextValidator
                      fullWidth
                      label="Kod pocztowy"
                      onChange={handleChange}
                      name="postalCode"
                      value={data.postalCode || ""}
                      variant="outlined"
                      validators={[
                        // "required",
                        "matchRegexp:^([0-9]{2})(-[0-9]{3})?$",
                      ]}
                      errorMessages={[
                        // "Pole jest wymagane",
                        "Nie poprawny format (00-000)",
                      ]}
                      helperText="Format 00-000"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextValidator
                      fullWidth
                      label="Miasto"
                      onChange={handleChange}
                      name="city"
                      value={data.city || ""}
                      variant="outlined"
                      validators={
                        [
                          // "required"
                        ]
                      }
                      errorMessages={
                        [
                          // "Pole jest wymagane"
                        ]
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextValidator
                      fullWidth
                      label="Email"
                      onChange={handleChange}
                      name="email"
                      value={data.email || ""}
                      variant="outlined"
                      validators={[
                        // "required",
                        "isEmail",
                      ]}
                      errorMessages={[
                        // "Pole jest wymagane",
                        "Pole e-mail nie jest poprawne",
                      ]}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextValidator
                      fullWidth
                      label="Numer telefonu"
                      onChange={handleChange}
                      name="phone"
                      value={data.phone || ""}
                      variant="outlined"
                      validators={[
                        // "required",
                        "matchRegexp:^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{5,6}$",
                      ]}
                      helperText="Format +48111222333"
                      errorMessages={[
                        // "Pole jest wymagane",
                        "Nie poprawny format (np. 048111222333)",
                      ]}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <LoadingButton
                  loading={loadingBtn}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                  type={"submit"}
                >
                  Zapisz
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Box>
  );
}
