import React, { useState } from "react";
import {Box, Button, Divider, List, Stack, Typography} from "@mui/material";
import CardGroupRaportTextItem from "./CardGroupRaportTextItem";
import ICardMark from "../../../types/ICardMark";
import useAlerts from "../../../hooks/useAlerts";
import { createCardMark, deleteCardMark } from "../../../services/card.service";

export default function CardGroupRaportTexts({
    data, itemId, blocked
}: {
    data: ICardMark[],
    itemId: number,
    blocked: boolean
}) {
    const [marks, setMarks] = useState<ICardMark[]>(data || [])

    const { error, success } = useAlerts()

    function handleCreateMark() {
        createCardMark(itemId)
            .then(({ data }) => setMarks([...marks, data]))
            .catch(err => error('ups, coś poszło nie tak'))
    }

    function handleDelete(markId: string | number) {
        deleteCardMark(markId)
            .then(() => {
                setMarks(marks.filter(i => i.id !== markId))
                success("Pomyślnie usunięto")
            })
            .catch(() => {
                error("Ups, coś poszło nie tak podczas usuwania")
            })
    }

    return <Box mt={2}>
        <Divider />
        <Typography gutterBottom variant={"caption"}>Odpowiedzi do raportu</Typography>
        <List>
            {marks.map((mark, ix) => <CardGroupRaportTextItem
                key={ix}
                data={mark}
                itemId={itemId}
                onDelete={() => handleDelete(mark.id)}
                blocked={blocked} />)}
            {!blocked && (
                <Stack width={'100%'} direction={'row-reverse'}>
                    <Button onClick={() => handleCreateMark()}>
                        Dodaj ocenianie
                    </Button>
                </Stack>
            )}
        </List>
    </Box>
}